import {
  Alert,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import {
  ChevronLeft,
  ChevronRight,
  ContentCopyOutlined,
  ForwardToInbox,
  SettingsBackupRestore,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { Form } from "react-final-form";
import RequestFormReadOnly, {
  getEmailBody,
  mapToInit,
} from "../requests/request-forms/RequestFormReadOnly";
import { LoadingButton } from "@mui/lab";
import { Page } from "react-pdf";
import { Document } from "react-pdf";
import ReqDownloadListBtn from "../../customs/ReqDownloadListBtn";
import PredefineRejectDesc from "./PredefineRejectDesc";
import Joi from "joi";
import validationMsg from "../../../helpers/validationMsg";
import { FORM_ERROR } from "final-form";
import DialogDescForm from "../../dialogs/DescFormDialog";
import _ from "lodash";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function CheckedRequestView({ caseId, reqId, handleCancel }) {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const pdfContainer = useRef();
  const pdfContainerLoadingWidth = useRef();
  const rednderPagination = () => {
    if (numPages == null) {
      return undefined;
    }

    return (
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
      >
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev - 1);
          }}
          disabled={pageNumber <= 1}
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => {
            pdfContainerLoadingWidth.current = pdfContainer.current.offsetWidth;
            setPageNumber((prev) => prev + 1);
          }}
          disabled={pageNumber >= numPages}
        >
          <ChevronRight />
        </Button>
      </ButtonGroup>
    );
  };

  // for fetch info
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(`api/cases/${caseId}/pol_requests/${reqId}`)
      .then(({ data }) => {
        if (data.status !== "checked") {
          throw new Error("status not equal to checked");
        }

        isSubscribed && setItem(data);

        const pdfLink = data.links?.find((link) => link.name === "export_pdf");

        if (!pdfLink?.link) {
          return undefined;
        }

        return api.get(pdfLink.link, {
          responseType: "arraybuffer",
        });
      })
      .then((response) => {
        if (!response) {
          return undefined;
        }

        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=".+"/.exec(contentDisposition)
          ? decodeURI(/filename="(.+)"/.exec(contentDisposition)[1])
          : "download";

        isSubscribed && setPdfFile({ filename, data: response.data });
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        isSubscribed && setLoading(false);
      });

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reqId]);

  const [submitting, setSubmitting] = useState(false);
  const handleConfirm = () => {
    setSubmitting(true);
    api
      .put(`api/cases/${caseId}/pol_requests/${reqId}/sent_to_bank`)
      .then(() => {
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch(({ response }) => {
        handleCancel({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {});
  };

  // reject confirmation
  const [cfReject, setCfReject] = useState(false);
  const handleCfRejectClose = () => setCfReject(false);
  const handleReject = (formValues) => {
    return api
      .put(`api/cases/${caseId}/pol_requests/${reqId}/reject`, formValues)
      .then(() => {
        handleCfRejectClose();
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch(({ response }) => {
        handleCfRejectClose();
        handleCancel({ type: "error", message: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {});
  };

  const modalStyle = {
    ...masterModalStyle,
    height: !loading && item ? "100%" : undefined,
    width: pdfContainerLoadingWidth.current
      ? `${pdfContainerLoadingWidth.current}px`
      : "auto",
  };

  // copy button state and function
  const [copied, SetCopied] = useState(false);

  const copyData = (copyValue) => {
    SetCopied(true);
    navigator.clipboard.writeText(copyValue);
  };

  useEffect(() => {
    let active = true;

    if (!copied) {
      return undefined;
    }

    setTimeout(() => {
      active && SetCopied(false);
    }, 5000);

    return () => {
      active = false;
    };
  }, [copied]);

  const emailBody = useMemo(() => {
    if (!item) return undefined;

    return getEmailBody(item);
  }, [item]);

  return (
    <Box sx={modalStyle} ref={pdfContainer}>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Form
        validate={validateReject}
        onSubmit={handleReject}
        component={DialogDescForm}
        open={cfReject}
        onClose={handleCfRejectClose}
        cfBtnProps={{ label: "ยืนยัน", color: "error" }}
        title="ยืนยันการปฏิเสธ"
        content={
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">
              โปรดอธิบายเหตุผลการปฎิเสธนี้
            </Typography>
            <PredefineRejectDesc />
          </Stack>
        }
        fieldProps={{
          name: "note",
          label: "เหตุผล",
          inputProps: {
            multiline: true,
            maxRows: 6,
            minRows: 3,
            autoFocus: true,
          },
        }}
      />
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <Typography id="modal-modal-title" variant="h6" component="div">
          ส่งคำขอ (ID: {reqId})
        </Typography>
        {!loading && item?.status && getChip(item.status)}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        {!loading && item?.links?.length > 0 && (
          <ReqDownloadListBtn links={item?.links} />
        )}
        {!loading && pdfFile && (
          <>
            {rednderPagination()}
            <Typography variant="subtitle1">
              Page {pageNumber} of {numPages}
            </Typography>
          </>
        )}
      </Stack>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        {loading || !item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <>
            <Grid container spacing={2} mt={2}>
              {pdfFile ? (
                <Grid item xs={12}>
                  <Document
                    file={pdfFile.data}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={1.4}
                      onLoadSuccess={() => {
                        pdfContainerLoadingWidth.current = null;
                      }}
                    />
                  </Document>
                </Grid>
              ) : (
                <Form
                  // validate={validate}
                  readOnly
                  onSubmit={() => undefined}
                  component={RequestFormReadOnly}
                  initialValues={mapToInit(item)}
                />
              )}
            </Grid>
          </>
        )}
      </Paper>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {loading || !item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom component="div">
                รายละเอียดการส่ง Email
              </Typography>
            </Grid>
            {!loading && item?.links?.length > 0 && (
              <Grid item xs>
                <Stack
                  direction="row"
                  spacing={2}
                  // justifyContent="flex-end"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <ReqDownloadListBtn links={item?.links}>
                    ดาวน์โหลดเอกสารแนบ
                  </ReqDownloadListBtn>
                </Stack>
              </Grid>
            )}
            {emailBody ? (
              <>
                <Grid item xs={12}>
                  <Divider textAlign="center">ข้อความ</Divider>
                </Grid>
                <Grid item xs={12} sx={{ whiteSpace: "pre-wrap" }}>
                  {emailBody}
                </Grid>
                <Grid item xs>
                  <Button
                    type="button"
                    size="small"
                    variant="outlined"
                    startIcon={<ContentCopyOutlined />}
                    onClick={() => copyData(emailBody)}
                    disabled={copied}
                  >
                    {copied ? "Copied" : "Copy"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  ไม่มี
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                type="button"
                variant="contained"
                startIcon={<ForwardToInbox />}
                onClick={() => handleConfirm()}
                loading={submitting}
                loadingPosition="start"
              >
                ส่ง email
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <LoadingButton
                  type="button"
                  variant="contained"
                  color="error"
                  startIcon={<SettingsBackupRestore />}
                  onClick={() => setCfReject(true)}
                  loading={submitting}
                  loadingPosition="start"
                >
                  ปฏิเสธ
                </LoadingButton>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                  disabled={submitting}
                >
                  ปิด
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
  );
}

const schema = Joi.object({
  note: Joi.string().required(),
}).error(validationMsg());

const validateReject = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  //   console.log("form errors");
  //   console.log(errors);
  return errors;
};
