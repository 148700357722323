import hr03Status from "../hr03Status";
import _ from "lodash";

const excludes = ["rtp_process1", "rtp_process2", "rtp_process3"];

const status = _.keys(hr03Status)
  .filter((status) => !excludes.includes(status))
  .map((status) => {
    const item = hr03Status[status];
    return { label: item.label, value: status };
  });

export default status;
