import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import RequestForm, { validate } from "./request-forms/RequestForm";
import api from "../../../apis";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import Resizer from "react-image-file-resizer";

export default function RequestAddPage() {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    let submitData = null;
    if (formData.signature && formData.signature instanceof File) {
      // resize before submit
      try {
        const signatureBase64 = await signatureResize(formData.signature);
        submitData = { ...formData, signature: signatureBase64 };
      } catch (e) {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          signature: "เกิดข้อผิดพลาด",
        };
      }
    } else {
      submitData = formData;
    }

    try {
      const res = await api.post(
        `/api/cases/${caseId}/pol_requests`,
        submitData
      );

      if (res.data.id) {
        navigate(`/cases/${caseId}/requests/${res.data.id}`);
      } else {
        navigate(`/cases/${caseId}/mgt`);
      }
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">สร้างคำขอ (ID: {caseId} )</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestForm}
          />
        </Paper>
      </Grid>
    </>
  );
}

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};
