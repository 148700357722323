import { AppRegistration, HowToReg } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useState } from "react";
import { Form } from "react-final-form";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import _ from "lodash";
import validationMsg from "../../../helpers/validationMsg";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import api from "../../../apis";
import { Link as RouterLink } from "react-router-dom";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().min(8).required(),
  confirm_password: Joi.string().min(8).required(),
  rank: Joi.string().max(255).required(),
  fname: Joi.string().max(255).required(),
  lname: Joi.string().max(255).required(),
  job_title: Joi.string().max(255).required(),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .required(),
}).error(validationMsg());

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (values.password !== values.confirm_password) {
    errors.confirm_password = "ยืนยันรหัสผ่านไม่ถูกต้อง";
  }

  if (
    !errors.email &&
    !values.email.endsWith("@police.go.th") &&
    !values.email.endsWith("@royalthaipolice.go.th") &&
    !values.email.endsWith("@rtp.go.th")
  ) {
    errors.email =
      "รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th เท่านั้น";
  }

  return errors;
};

export default function RegisterPage() {
  const [success, setSuccess] = useState(false);

  const onSubmit = (formValues) => {
    return api
      .post(`api/register`, formValues)
      .then(() => {
        setSuccess(formValues.email);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <AppRegistration />
        </Avatar>
        <Typography component="h1" variant="h5">
          ลงทะเบียน
        </Typography>
        {success ? (
          <Box sx={{ mt: 1 }}>
            <Alert severity="success" sx={{ width: "100%" }}>
              <AlertTitle>ลงทะเบียนสำเร็จ</AlertTitle>
              กรุณาตรวจสอบ email <strong>{success}</strong>{" "}
              เพื่อยืนยันการลงทะเบียน
            </Alert>
          </Box>
        ) : (
          <Form validate={validate} onSubmit={onSubmit}>
            {({
              handleSubmit,
              errors,
              error,
              submitError,
              submitting,
              pristine,
              initialValues,
              submitFailed,
              form,
              values,
            }) => {
              return (
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit}
                >
                  {submitFailed && (error || submitError) && (
                    <Alert severity="warning" sx={{ width: "100%" }}>
                      {error || submitError}
                    </Alert>
                  )}
                  <FieldInput
                    name="email"
                    label="Email"
                    required
                    controlProps={{ fullWidth: true, margin: "normal" }}
                    //   inputProps={{
                    //     placeholder: "email@police.go.th",
                    //   }}
                    helperText={
                      <>
                        รองรับอีเมล @police.go.th, @royalthaipolice.go.th หรือ
                        @rtp.go.th เท่านั้น{" "}
                        <Link
                          href="https://register.police.go.th/?q=register"
                          target="_blank"
                          rel="noopener"
                        >
                          (สมัครอีเมล police)
                        </Link>
                      </>
                    }
                  />
                  <FieldInput
                    name="password"
                    label="รหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                  />
                  <FieldInput
                    name="confirm_password"
                    label="ยืนยันรหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                  />
                  <Divider textAlign="center" sx={{ mb: 2, mt: 2 }}>
                    ข้อมูลผู้ใช้
                  </Divider>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FieldInput
                        name="rank"
                        label="ยศ"
                        controlProps={{ fullWidth: true }}
                        inputProps={{ placeholder: "คำเต็ม เช่น พันตำรวจโท" }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldInput
                        name="fname"
                        label="ชื่อ"
                        controlProps={{ fullWidth: true }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldInput
                        name="lname"
                        label="สกุล"
                        controlProps={{ fullWidth: true }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldInput
                        name="job_title"
                        label="ตำแหน่ง"
                        controlProps={{ fullWidth: true }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldAsyncAutoComplete
                        name="policestation_org_code"
                        label="หน่วยงาน"
                        required
                        controlProp={{ fullWidth: true }}
                        basePath="/api/policestations"
                        itemFormat={(row) => {
                          return { value: row.ORG_CODE, label: row.ORG_ABBR };
                        }}
                      />
                    </Grid>
                    <Grid item xs md>
                      <FieldInput
                        name="pol_tel"
                        label="โทรศัพท์"
                        required
                        controlProps={{ fullWidth: true }}
                      />
                    </Grid>
                  </Grid>
                  <SubmitBtn
                    variant="contained"
                    submitting={submitting}
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<HowToReg />}
                    size="large"
                  >
                    ลงทะเบียน
                  </SubmitBtn>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      <Link component={RouterLink} to="/login" variant="body2">
                        กลับไปหน้าเข้าสู่ระบบ
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Form>
        )}
      </Box>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
}
