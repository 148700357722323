import * as React from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import {
  AccountCircle,
  AutoAwesomeMotion,
  Dashboard,
  FindInPage,
  Inbox,
  Key,
  Logout,
  Person,
  PersonOff,
  RequestPage,
  Rule,
  Search,
  TextSnippet,
  UploadFile,
} from "@mui/icons-material";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MenuItemBotton from "./MenuItemBotton";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../ducks/auth";
import CaseMainMenuBotton from "./CaseMainMenuBotton";
import RequireRoles from "../RequireRoles";
import CollapseMenuItemBotton from "../templates/CollapseMenuItemBotton";
import MuiLink from "@mui/material/Link";
import LineConnectDialog from "../pages/LineConnectDialog";
import { nameComposer } from "../../helpers/stringFormater";
const drawerWidth = 240;

function Copyright(props) {
  const [normCount, setNormCount] = React.useState(0);
  const navigate = useNavigate();
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        onClick={() => {
          setNormCount((prev) => ++prev);
          if (normCount > 6) {
            setNormCount(0);
            navigate("/admin/file-normalize");
          }
        }}
        {...props}
      >
        CCIB-DEV@
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        <MuiLink
          rel="noopener"
          target="_blank"
          href="https://sites.google.com/view/pctpolice/home"
        >
          คู่มือการใช้งาน
        </MuiLink>
        , ติดต่อผู้ดูแลระบบทาง{" "}
        <MuiLink rel="noopener" target="_blank" href="https://lin.ee/u3MNdBI">
          Line Official Account
        </MuiLink>
      </Typography>
    </>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function Main() {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [lineOpen, setLineOpen] = React.useState(false);
  const handleLineConnect = () => {
    setLineOpen(true);
  };

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authUsername = useSelector((state) => state.auth.user.email);
  const lineNotiLink = useSelector((state) => state.auth.user.line_notify_link);
  const lineNotiToken = useSelector((state) => state.auth.user.notify_token);

  const displayName = useSelector((state) =>
    nameComposer(
      state.auth.user.rank,
      state.auth.user.fname,
      state.auth.user.lname
    )
  );
  const displayOrg = useSelector((state) => state.auth.user.org?.ORG_ABBR);
  let location = useLocation();

  //signOut handler
  const signOut = React.useCallback(() => {
    dispatch(authLogout());
    // console.log("call logout");
    // history.push("/login");
  }, [dispatch]);

  // auth check
  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <LineConnectDialog
        open={lineOpen}
        link={lineNotiLink}
        onClose={() => setLineOpen(false)}
      />
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "5px",
              // ...(open && { display: "none" }),
            }}
          >
            {/* <MenuIcon /> */}
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/images/rtp-logo.png`}
              alt="Logo"
              height="48px"
            />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Bank Portal
          </Typography>
          <Typography>{authUsername}</Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItemBotton
                component={MenuItem}
                to="/me"
                onClick={handleClose}
                exact
              >
                <ListItemIcon>
                  <AccountCircle fontSize="small" />
                </ListItemIcon>
                <ListItemText>ข้อมูลผู้ใช้</ListItemText>
              </MenuItemBotton>
              <MenuItemBotton
                component={MenuItem}
                to="/me/chpassword"
                onClick={handleClose}
              >
                <ListItemIcon>
                  <Key fontSize="small" />
                </ListItemIcon>
                <ListItemText>เปลี่ยนรหัสผ่าน</ListItemText>
              </MenuItemBotton>
              <MenuItem onClick={signOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>ออกจากระบบ</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ height: "100vh", overflow: "auto" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          {/* <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton> */}
        </Toolbar>
        <Divider />
        <List sx={{ p: 0 }}>
          <div>
            <Box
              sx={{
                p: 2,
                display: "flex",
                textDecoration: "none",
                color: "inherit",
                minWidth: drawerWidth,
              }}
              component={Link}
              to="/me"
            >
              <Avatar variant="rounded">
                <Person />
              </Avatar>
              <Stack
                spacing={0.5}
                sx={{
                  ml: 2,
                  // whiteSpace: "normal",
                  minWidth: 0,
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={700}
                  noWrap
                  sx={{ whiteSpace: "normal" }}
                >
                  {displayName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ whiteSpace: "normal" }}
                  noWrap
                >
                  {displayOrg}
                </Typography>
              </Stack>
            </Box>
          </div>
        </List>
        <Divider />
        <List>
          <div>
            <ListSubheader inset>ข้อมูลของฉัน</ListSubheader>
            {/* <MenuItemBotton to="/cases">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="รายการรับแจ้ง" />
            </MenuItemBotton> */}
            <CaseMainMenuBotton />

            {/* <CollapseMenuItemBotton
              to="/testcases"
              icon={<Inbox />}
              text="icetest"
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Starred" />
              </ListItemButton>
            </CollapseMenuItemBotton> */}
            <MenuItemBotton to="/requests">
              <ListItemIcon>
                <RequestPage />
              </ListItemIcon>
              <ListItemText primary="คำขอทั้งหมด" />
            </MenuItemBotton>
            {/* <MenuItemBotton to="/noaccounts">
              <ListItemIcon>
                <BedroomBaby />
              </ListItemIcon>
              <ListItemText primary="บันทึกข้อมูลบัญชีม้า" />
            </MenuItemBotton> */}
            <MenuItemBotton to="/hr03" exact>
              <ListItemIcon>
                <PersonOff />
              </ListItemIcon>
              <ListItemText primary="บันทึกบัญชี HR03" />
            </MenuItemBotton>
            <RequireRoles roleIds={[2, 4]}>
              <MenuItemBotton to="/hr03/mgt" exact>
                <ListItemIcon>
                  <Rule />
                </ListItemIcon>
                <ListItemText primary="ตรวจสอบข้อมูล HR03" />
              </MenuItemBotton>
            </RequireRoles>
            {!lineNotiToken && (
              <ListItemButton onClick={handleLineConnect}>
                <ListItemIcon>
                  <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/images/line-icon.png`}
                    alt="Logo"
                    height="23px"
                  />
                </ListItemIcon>
                <ListItemText primary="รับแจ้งเตือนทาง Line" />
              </ListItemButton>
            )}
          </div>
        </List>
        <List>
          <div>
            <ListSubheader inset>ข้อมูลระบบ</ListSubheader>
            <MenuItemBotton to="/acc-search">
              <ListItemIcon>
                <FindInPage />
              </ListItemIcon>
              <ListItemText primary="ค้นหาบัญชี" />
            </MenuItemBotton>
            <RequireRoles roleIds={[2, 5]}>
              <MenuItemBotton to="/req-search">
                <ListItemIcon>
                  <FindInPage />
                </ListItemIcon>
                <ListItemText primary="คำขอของหน่วยงาน" />
              </MenuItemBotton>
            </RequireRoles>
          </div>
        </List>
        <RequireRoles roleIds={[2]}>
          <Divider />
          <List>
            <div>
              <ListSubheader inset>Admin</ListSubheader>
              <CollapseMenuItemBotton
                to="/admin/request-mgt"
                icon={<Inbox />}
                text="จัดการคำขอ"
              >
                <MenuItemBotton
                  to="/admin/request-mgt/sent_to_contact_person"
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="คำขอเข้าใหม่" />
                </MenuItemBotton>

                <MenuItemBotton
                  to="/admin/request-mgt/checked"
                  exact
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="คำขอตรวจแล้ว" />
                </MenuItemBotton>
                <MenuItemBotton
                  to="/admin/request-mgt/checked-sum"
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <AutoAwesomeMotion />
                  </ListItemIcon>
                  <ListItemText primary="คำขอตรวจแล้ว(กลุ่ม)" />
                </MenuItemBotton>
                <MenuItemBotton to="/admin/request-mgt/sum-req" sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <AutoAwesomeMotion />
                  </ListItemIcon>
                  <ListItemText primary="template มัดรวม" />
                </MenuItemBotton>
                <MenuItemBotton to="/admin/request-mgt" exact sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  <ListItemText primary="คำขอทั้งหมด" />
                </MenuItemBotton>
              </CollapseMenuItemBotton>
              <MenuItemBotton to="/admin/responses/upload">
                <ListItemIcon>
                  <UploadFile />
                </ListItemIcon>
                <ListItemText primary="Template Upload" />
              </MenuItemBotton>
              <MenuItemBotton to="/admin/file-normalize">
                <ListItemIcon>
                  <TextSnippet />
                </ListItemIcon>
                <ListItemText primary="File Normalization" />
              </MenuItemBotton>
            </div>
          </List>
        </RequireRoles>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          // height: "auto",
          overflow: "auto",
        }}
        id="main-container"
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default Main;
