import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import FieldAccountCctv from "../../../form-fields/customs/FieldAccountCctv";
import LetterForm from "./sub-forms/LetterForm";
import { useField } from "react-final-form";

export default function CctvRequest({ readOnly, bankAccounts }) {
  const bankIdField = useField("bank_id");

  if (!bankIdField.input.value) {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="error">
          โปรดเลือกธนาคาร
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">รายการบัญชี</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldAccountCctv
          name="bank_accounts"
          readOnly={readOnly}
          rows={bankAccounts}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center"></Divider>
      </Grid>
      <LetterForm readOnly={readOnly} />
    </>
  );
}
