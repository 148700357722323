import { Paper, Typography } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import AccountForm, { validate } from "./AccountForm";
import api from "../../../apis";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forceReload as reloadAccountList } from "../../../ducks/accountList";
import { forceReload as reloadTransactionList } from "../../../ducks/transactionSearch";

export default function AccountAddPage({ handleCancel, tsFieldName }) {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();
  const transactionForm = useSelector(
    (state) => state.finalForm?.transaction?.form
  );
  const transactionFormValues = useSelector(
    (state) => state.finalForm?.transaction?.state?.values
  );

  const onSubmit = (formValues) => {
    return api
      .post(`/api/cases/${caseId}/bank_accounts`, formValues)
      .then(({ data }) => {
        if (tsFieldName) {
          const resetField = {};
          if (tsFieldName === "src_bank_account_id") {
            resetField.src_bank_account_id = data.id;
          }
          if (tsFieldName === "des_bank_account_id") {
            resetField.des_bank_account_id = data.id;
          }

          if (!_.isEmpty(resetField)) {
            transactionForm.batch(() => {
              resetField.src_bank_account_id &&
                transactionForm.change("src_bank_account_id", undefined); // listeners not notified
              resetField.des_bank_account_id &&
                transactionForm.change("des_bank_account_id", undefined); // listeners not notified
            });

            transactionForm.reset({ ...transactionFormValues, ...resetField });
            dispatch(reloadTransactionList());
          }
        }
        dispatch(reloadAccountList());
        handleCancel();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        เพิ่มบัญชี
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          component={AccountForm}
          handleCancel={handleCancel}
        />
      </Paper>
    </>
  );
}
