import api from "../apis";
import _ from "lodash";
// Actions
const MRQ_FETCH = "mgtReqList/FETCH";
const MRQ_CLEAR_RESULT = "mgtReqList/CLEAR_RESULT";
const MRQ_MARK_LOADING = "mgtReqList/MARK_LOADING";
const MRQ_SET_FILTER = "mgtReqList/SET_FILTER";
const MRQ_FORCE_RELOAD = "mgtReqList/FORCE_RELOAD";
const MRQ_CHANGE_PAGE = "mgtReqList/CHANGE_PAGE";
const MRQ_CHANGE_PAGE_SIZE = "mgtReqList/CHANGE_PAGE_SIZE";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case MRQ_FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case MRQ_CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0, page: 0 },
      };
    case MRQ_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case MRQ_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
          page: 0,
        },
      };
    case MRQ_FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    case MRQ_CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case MRQ_CHANGE_PAGE_SIZE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: 0,
          pageSize: action.payload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const mrqFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(mrqMarkLoading(true));

    try {
      response = await api.get(`/api/mgt/pol_requests/list_request`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: MRQ_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      dispatch(mrqClearResult());
      throw e;
    }
  };

export const mrqMarkLoading = (isLoading = true) => {
  return { type: MRQ_MARK_LOADING, payload: isLoading };
};

export const mrqClearResult = () => {
  return { type: MRQ_CLEAR_RESULT };
};

export const mrqSetFilter = (filterValue) => {
  return { type: MRQ_SET_FILTER, payload: filterValue };
};

export const mrqForceReload = () => {
  return { type: MRQ_FORCE_RELOAD };
};

export const mrqChangePage = (page) => {
  return { type: MRQ_CHANGE_PAGE, payload: page };
};

export const mrqChangePageSize = (pageSize) => {
  return { type: MRQ_CHANGE_PAGE_SIZE, payload: pageSize };
};
