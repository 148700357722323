const roles = {
  police: {
    id: 1,
    name: "ทั่วไป",
    desc: "ผู้ใช้(พงส.) สามารถสร้างคำขอหรือหมายไปยังธนาคารและรวมถึงการสร้างบัญชี HR03",
  },
  hr_bh: {
    id: 4,
    name: "Admin HR03",
    desc: "ผู้พิจารณาบัญชี HR03 ในระดับกองบัญชาการ",
    scopeOrgKey: "hr03_org_code",
  },
  viewer: {
    id: 5,
    name: "ผู้ตรวจสอบข้อมูล",
    desc: "สามารถค้นข้อมูลคำขอในระบบฯตามระดับสิทธิ์ที่กำหนด",
    scopeOrgKey: "viewer_org_code",
  },
};

export default roles;
