import { ExpandMore, Link as LinkIcon, StarBorder } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useMatch, useResolvedPath } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
export default function CaseMainMenuBotton() {
  const to = "/cases";
  const exact = false;

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: exact ? true : false });

  let resolveCaseMgt = useResolvedPath("/cases/:id/mgt");
  let matchCaseMgt = useMatch({ path: resolveCaseMgt.pathname, end: false });

  const [activeCaseInfo, setActiveCaseInfo] = useState(false);
  const [activeAccount, setActiveAccount] = useState(false);
  // const [activeTransaction, setActiveTransaction] = useState(false);
  const [activeLetter, setActiveLetter] = useState(false);

  return (
    <>
      <ListItemButton
        component={ReactRouterLink}
        selected={match ? true : false}
        to={to}
      >
        <ListItemIcon>
          <StarBorder />
        </ListItemIcon>
        <ListItemText primary="รายการ Case" />
        {matchCaseMgt && <ExpandMore />}
      </ListItemButton>
      <Collapse in={matchCaseMgt ? true : false} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ScrollLink
            to="case-info"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveCaseInfo(true);
            }}
            onSetInactive={() => {
              setActiveCaseInfo(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeCaseInfo}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="ข้อมูลเบื้องต้น" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="tx-account"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveAccount(true);
            }}
            onSetInactive={() => {
              setActiveAccount(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeAccount}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="รายการเดินบัญชี" />
            </ListItemButton>
          </ScrollLink>
          <ScrollLink
            to="letter"
            smooth={true}
            duration={500}
            containerId="main-container"
            spy={true}
            onSetActive={() => {
              setActiveLetter(true);
            }}
            onSetInactive={() => {
              setActiveLetter(false);
            }}
            offset={-100}
          >
            <ListItemButton sx={{ pl: 4 }} selected={activeLetter}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="รายการคำขอ" />
            </ListItemButton>
          </ScrollLink>
        </List>
      </Collapse>
    </>
  );
}
