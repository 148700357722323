import * as React from "react";

import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import thLocale from "date-fns/locale/th";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import Main from "./components/templates/Main";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import CaseListPage from "./components/pages/cases/CaseListPage";
import CaseAddPage from "./components/pages/cases/CaseAddPage";
import TestPage from "./components/pages/TestPage";
import { useSelector } from "react-redux";
import CaseMgtPage from "./components/pages/cases-mgt/CaseMgtPage";
import LoadingPage from "./components/pages/LoadingPage";
import InitFail from "./components/pages/InitFail";
import RequestAddPage from "./components/pages/requests/RequestAddPage";
import "./components/templates/dataGridCellWrap.css";
import ChPassowrdPage from "./components/pages/ChPassowrdPage";
import RequestViewPage from "./components/pages/requests/RequestViewPage";
import RegisterPage from "./components/pages/register/RegisterPage";
import UserActivationPage from "./components/pages/register/UserActivationPage";
import ProfileViewEditPage from "./components/pages/user-profile/ProfileViewEditPage";
import ForgotPasswordPage from "./components/pages/register/ForgotPasswordPage";
import ForgotSetPasswordPage from "./components/pages/register/ForgotSetPasswordPage";
import RequireRoles from "./components/RequireRoles";
import RedirectIfAuthenticate from "./components/RedirectIfAuthenticate";
import RequestListPage from "./components/pages/request-mgt/RequestListPage";
import CheckedSumRequestPage from "./components/pages/request-mgt/CheckedSumRequestPage";
import ResponseUploadPage from "./components/pages/responses/uploads/ResponseUploadPage";
import AccountSearchPage from "./components/pages/account-search/AccountSearchPage";
import Hr03ListPage from "./components/pages/hr03/Hr03ListPage";
import Hr03AccountAddPage from "./components/pages/hr03/Hr03AccountAddPage";
import Hr03MgtListPage from "./components/pages/hr03-mgt/Hr03MgtListPage";
import Hr03AccountEditPage from "./components/pages/hr03/Hr03AccountEditPage";
import SumRequestPage from "./components/pages/request-mgt/sum-req/SumRequestPage";
import RequestSearchPage from "./components/pages/requests-search/RequestSearchPage";
import LineCallbackPage from "./components/pages/user-profile/LineCallbackPage";
import UploadPage from "./components/pages/template-norm/UploadPage";
import MaPage from "./components/pages/MaPage";
import RequestTelcoAddPage from "./components/pages/requests-telco/RequestTelcoAddPage";
import { thTH } from "@mui/material/locale";

thTH.components.MuiDataGrid = {
  defaultProps: {
    localeText: {
      filterValueAny: "ทั้งหมด",
      filterValueTrue: "ใช่",
      filterValueFalse: "ไม่ใช่",
    },
  },
};

function App() {
  const isAuthFetching = useSelector((state) => state.auth.isFetching);
  const appInitSuccess = useSelector((state) => state.app.initSuccess);
  const appLoading = useSelector((state) => state.app.isLoading);
  const maintenance = useSelector((state) => state.app.maintenance.status);

  const theme = useTheme();

  const mdTheme = React.useMemo(() => createTheme(theme, thTH), [theme]);

  const render = () => {
    if (isAuthFetching || appLoading) {
      return <LoadingPage />;
    }

    if (maintenance) {
      return <MaPage />;
    }

    if (appInitSuccess === false) {
      return <InitFail />;
    }

    return (
      <Routes>
        <Route
          path="/login"
          element={
            <RedirectIfAuthenticate>
              <LoginPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/register"
          element={
            <RedirectIfAuthenticate>
              <RegisterPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <RedirectIfAuthenticate>
              <ForgotPasswordPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/forgotpassword/setpassword/:token"
          element={
            <RedirectIfAuthenticate>
              <ForgotSetPasswordPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route
          path="/confirm/:activate_code"
          element={
            <RedirectIfAuthenticate>
              <UserActivationPage />
            </RedirectIfAuthenticate>
          }
        />
        <Route path="/notfound" element={<div>Not Found</div>} />
        <Route path="/" element={<Main />}>
          <Route path="/me" element={<ProfileViewEditPage />} />
          <Route path="/me/chpassword" element={<ChPassowrdPage />} />
          <Route path="/line_callback" element={<LineCallbackPage />} />
          <Route path="/cases" element={<CaseListPage />} />
          <Route path="/cases/add" element={<CaseAddPage />} />
          <Route path="/form-test" element={<TestPage />} />
          <Route path="/cases/:id/mgt" element={<CaseMgtPage />} />
          <Route path="/cases/:id/requests/add" element={<RequestAddPage />} />
          <Route
            path="/cases/:id/requests/add/:type_id"
            element={<RequestAddPage />}
          />
          <Route
            path="/cases/:id/requests-telco/add"
            element={<RequestTelcoAddPage />}
          />
          <Route
            path="/cases/:id/requests/:reqId"
            element={<RequestViewPage />}
          />
          <Route path="/requests" element={<RequestListPage />} />
          {/* <Route path="/noaccounts" element={<NomAccountListPage />} /> */}
          <Route path="/acc-search" element={<AccountSearchPage />} />
          <Route
            path="/req-search"
            element={
              <RequireRoles roleIds={[2, 5]} navigate>
                <RequestSearchPage />
              </RequireRoles>
            }
          />
          <Route path="/hr03" element={<Hr03ListPage />} />
          <Route path="/hr03/add" element={<Hr03AccountAddPage />} />
          <Route path="/hr03/edit/:id" element={<Hr03AccountEditPage />} />

          <Route
            path="/hr03/mgt"
            element={
              <RequireRoles roleIds={[2, 4]} navigate>
                <Hr03MgtListPage />
              </RequireRoles>
            }
          />

          <Route
            path="admin/request-mgt"
            element={
              <RequireRoles roleIds={[2]} navigate>
                <RequestListPage />
              </RequireRoles>
            }
          />
          <Route
            path="admin/request-mgt/checked-sum"
            element={
              <RequireRoles roleIds={[2]} navigate>
                <CheckedSumRequestPage />
              </RequireRoles>
            }
          />
          <Route
            path="admin/request-mgt/sum-req"
            element={
              <RequireRoles roleIds={[2]} navigate>
                <SumRequestPage />
              </RequireRoles>
            }
          />
          <Route
            path="/admin/request-mgt/:status"
            element={
              <RequireRoles roleIds={[2]} navigate>
                <RequestListPage />
              </RequireRoles>
            }
          />

          <Route
            path="/admin/responses/upload"
            element={
              <RequireRoles roleIds={[2]} navigate>
                <ResponseUploadPage />
              </RequireRoles>
            }
          />

          <Route path="/admin/file-normalize" element={<UploadPage />} />

          <Route path="/" element={<Navigate to="/cases" replace />} />
          <Route path="*" element={<Navigate to="/notfound" replace />} />
        </Route>
      </Routes>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={thLocale}>
      <ThemeProvider theme={mdTheme}>
        <BrowserRouter>{render()}</BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
