import {
  getGridBooleanOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";

const customStringFilterOperator = [
  {
    value: "contains",
    label: "ประกอบด้วย",
    getValueAsString: (value) => {
      console.log(value);
      return "eieie";
    },
  },
  { value: "equals", label: "เท่ากับ" },
  { value: "startsWith", label: "ขึ้นต้นด้วย" },
  { value: "endsWith", label: "ลงท้ายด้วย" },
  { value: "isEmpty", label: "ค่าว่าง" },
  { value: "isNotEmpty", label: "ค่าไม่ว่าง" },
  { value: "isAnyOf", label: "ใดๆ เท่ากับ" },
];

const customBooleanFilterOperator = [
  {
    value: "is",
    label: "เท่ากับ",
    getValueAsString: (value) => (value === "true" ? "ใช่" : "ไม่ใช่"),
  },
];

export const gridStringOperators = (options = {}) =>
  getGridStringOperators().map((op) => {
    const customOp = customStringFilterOperator.find(
      (cf) => cf.value === op.value
    );
    return { ...op, ...customOp, ...options };
  });

export const gridBooleanOperators = (options = {}) =>
  getGridBooleanOperators().map((op) => {
    const customOp = customBooleanFilterOperator.find(
      (cf) => cf.value === op.value
    );
    return { ...op, ...customOp, ...options };
  });
