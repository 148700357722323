import { Chip, Tooltip } from "@mui/material";

const requestStatus = {
  new: { label: "พงส.", color: "warning" },
  sent_to_bh: {
    label: "บช. พิจารณา",
    color: "warning",
    desc: "อยู่ในระหว่างคณะทำงานระดับ บช. พิจารณา",
  },
  bh_reject: {
    label: "บช. ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน บช. ปฏิเสธ",
    stepProps: { error: true },
  },
  bh_approve: {
    label: "ตร. พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่างคณะทำงานระดับ ตร. พิจารณา",
  },
  rtp_reject: {
    label: "ตร. ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน ตร. ปฏิเสธ",
    stepProps: { error: true },
  },
  rtp_approve: {
    label: "ตร. อนุมติ",
    color: "info",
    desc: "คณะทำงาน ตร. ตรวจสอบแล้ว",
  },
  sent_to_aml: {
    label: "ปปง. พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่าง ปปง. พิจารณา",
  },
  hr03: {
    label: "HR03",
    color: "success",
    desc: "ขึ้นบัญชี hr03 แล้ว",
  },
  rtp_process1: {
    label: "เตรียมเข้าประชุม",
    color: "success",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  rtp_process2: {
    label: "เตรียมเข้าประชุม",
    color: "success",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  rtp_process3: {
    label: "เตรียมเข้าประชุม",
    color: "success",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
};

export default requestStatus;

export const getChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Tooltip placement="top" arrow title="ไม่พบสถานะ กรุณาติดต่อ admin">
        <Chip label="unknow" color="error" {...props} />
      </Tooltip>
    );
  }

  let chip = (
    <Chip label={reqStatus.label} color={reqStatus.color} {...props} />
  );

  if (reqStatus.desc) {
    chip = (
      <Tooltip placement="top" arrow title={reqStatus.desc}>
        {chip}
      </Tooltip>
    );
  }

  return chip;
};

export const getDescChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Chip label="ไม่พบสถานะ กรุณาติดต่อ admin" color="error" {...props} />
    );
  }

  return (
    <Chip
      label={reqStatus.desc || reqStatus.label}
      color={reqStatus.color}
      {...props}
    />
  );
};

export const masterSteps = [
  "new",
  "sent_to_bh",
  ["bh_approve", "bh_reject"],
  ["rtp_process1", "rtp_process2", "rtp_process3"],
  ["rtp_approve", "rtp_reject"],
  "sent_to_aml",
  "hr03",
];
