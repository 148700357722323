import {
  Container,
  CssBaseline,
  LinearProgress,
  Typography,
  Box,
  Stack,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import api from "../../../apis";
import { CheckCircle, Cancel, Login } from "@mui/icons-material";

export default function UserActivationPage() {
  const params = useParams();
  const activateCode = params.activate_code;

  const [result, setResult] = useState(null);

  useEffect(() => {
    api
      .get(`api/confirm/${activateCode}`)
      .then(({ data }) => {
        setResult(
          <>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              sx={{ mb: 1 }}
            >
              <CheckCircle fontSize="large" color="success" />
              <Typography component="h1" variant="h5" gutterBottom>
                ยืนยัน email สำเร็จ
              </Typography>
            </Stack>
            <Box sx={{ width: "100%", mb: 1 }}>
              <LinearProgress
                variant="determinate"
                color="success"
                value={100}
              />
            </Box>
            <Button
              component={Link}
              to="/login"
              variant="outlined"
              startIcon={<Login />}
            >
              เข้าสู่ระบบ
            </Button>
          </>
        );
      })
      .catch(({ response }) => {
        setResult(
          <>
            <Stack
              direction="row"
              spacing={1}
              alignContent="center"
              sx={{ mb: 1 }}
            >
              <Cancel fontSize="large" color="error" />
              <Typography component="h1" variant="h5" gutterBottom>
                ยืนยัน email ไม่สำเร็จ
              </Typography>
            </Stack>
            <Box sx={{ width: "100%", mb: 1 }}>
              <LinearProgress variant="determinate" color="error" value={100} />
            </Box>
            <Button
              component={Link}
              to="/login"
              variant="outlined"
              startIcon={<Login />}
            >
              กลับไปหน้าเข้าสู่ระบบ
            </Button>
          </>
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {result || (
          <>
            <Typography component="h1" variant="h5" gutterBottom>
              Loading....
            </Typography>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}
