import React, { useEffect, useState } from "react";
import _ from "lodash";
import Joi from "joi";
import { FORM_ERROR } from "final-form";
import { format, isAfter, parseISO, startOfToday } from "date-fns";
import validationMsg from "../../../../helpers/validationMsg";
import {
  Divider,
  Grid,
  Stack,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Snackbar,
} from "@mui/material";
import FieldSelect from "../../../form-fields/FieldSelect";
import allLetterTypes from "../../../../data/selects/letterTypes";
import api from "../../../../apis";
import { useNavigate, useParams } from "react-router-dom";
import SubmitBtn from "../../../SubmitBtn";
import { Mail } from "@mui/icons-material";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import { telcoOptionsQuery } from "../../../../data/queries/telco";
import { useQuery } from "react-query";
import RegistrationRequest from "./RegistrationRequest";
import {
  letterValidationMsg,
  validateLetterRules,
} from "../../requests/request-forms/sub-forms/LetterForm";
import CdrRequest from "./CdrRequest";

const letterTypeIds = [9, 10];

const letterTypes = allLetterTypes.filter((l) => l.dest_type === "telco");

export default function RequestTelcoForm({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();

  // error & warning dialog state
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const {
    isLoading: isTelcoOptionsLoading,
    data: telcoOptions,
    // error: telcoOptionsError,
  } = useQuery(telcoOptionsQuery());

  // snack bar
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // form submit error
  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  // init form value when type id change
  useEffect(() => {
    if (!values.request_type_id || readOnly) {
      return undefined;
    }

    let initData = { request_type_id: values.request_type_id };
    if (form.getRegisteredFields().some((field) => field === "date")) {
      initData = { ...initData, date: format(new Date(), "yyyy-MM-dd") };
    }

    if (values.request_type_id === 8) {
      initData = { ...initData, nationality: "TH" };
    }

    if (letterTypeIds.includes(values.request_type_id)) {
      api
        .get(`/api/me`)
        .then(({ data }) => {
          initData = {
            ...initData,
            rank: data.rank,
            job_title: data.job_title,
            fname: data.fname,
            lname: data.lname,
            policestation_org_code: data.policestation_org_code?.match(
              /^[2-9]\d{4}$/
            )
              ? data.policestation_org_code
              : undefined,
            email: data.email,
            tel: data.pol_tel,
            address: data.pol_address,
            date: format(new Date(), "yyyy-MM-dd"),
          };
          form.reset(initData);
        })
        .catch(() => {
          SetToastInfo({
            type: "error",
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลหมายเบื้องต้น",
          });
        });
    } else {
      form.reset(initData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.request_type_id]);

  const renderBankIdField = () => {
    return (
      <FieldSelect
        name="bank_id"
        label="ผู้ให้บริการ"
        options={telcoOptions}
        readOnly={readOnly}
        required
        controlProp={{ sx: { width: "50%" } }}
        disabled={isTelcoOptionsLoading}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid container spacing={2} justifyContent="center">
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <FieldSelect
              name="request_type_id"
              label="ประเภทคำขอ"
              options={letterTypes}
              readOnly={readOnly}
              required
              controlProp={{ sx: { width: "50%" } }}
              disabled={isTelcoOptionsLoading}
            />
            {renderBankIdField()}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center"></Divider>
        </Grid>
        {isTelcoOptionsLoading ? (
          <Grid item md={12}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Grid>
        ) : (
          <>
            {values.request_type_id &&
              renderRequest(readOnly, values.request_type_id)}
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                {!readOnly && (
                  <SubmitBtn
                    variant="contained"
                    startIcon={<Mail />}
                    submitting={submitting}
                    pristine={pristine}
                  >
                    สร้างคำขอ
                  </SubmitBtn>
                )}
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    navigate(`/cases/${caseId}/mgt`);
                  }}
                >
                  {readOnly ? "ปิด" : "ยกเลิก"}
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

const renderRequest = (readOnly, request_type_id) => {
  switch (request_type_id) {
    case 9:
      return <RegistrationRequest readOnly={readOnly} />;
    case 10:
      return <CdrRequest readOnly={readOnly} />;
    default:
      return (
        <Grid item xs={12}>
          not support yet
        </Grid>
      );
  }
};

export const validate = (values) => {
  const errors = {};
  let vResult = null;

  // validate specific requeset type rule
  switch (values.request_type_id) {
    case 9:
      vResult = registrationRequestSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 10:
      vResult = cdrRequestSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    default:
      errors.request_type_id = values.request_type_id
        ? "not support"
        : "โปรดเลือก";
      return errors;
  }

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    values.date &&
    !errors.date &&
    isAfter(parseISO(values.date), new Date())
  ) {
    errors.date = "มากกว่าเวลาปัจจุบัน";
  }

  if (
    values.from_date &&
    values.to_date &&
    !errors.from_date &&
    !errors.to_date &&
    isAfter(parseISO(values.from_date), parseISO(values.to_date))
  ) {
    errors.from_date = "ไม่ถูกต้อง";
    errors.to_date = "ไม่ถูกต้อง";
  }

  if (
    values.signature &&
    !errors.signature &&
    values.signature instanceof File &&
    !values.signature.type?.startsWith("image/")
  ) {
    errors.signature = "รูปภาพเท่านั้น";
  }

  // console.log(errors);

  return errors;
};

const validateCommonRules = {
  request_type_id: Joi.number().min(9).max(10).required(),
  bank_id: Joi.number().min(1).required(),
};

const registrationRequestRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  phone_number: Joi.string()
    .pattern(/^0\d{9}$/)
    .required(),
});

const cdrRequestRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  phone_number: Joi.string()
    .pattern(/^0\d{9}$/)
    .required(),
  from_date: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
  to_date: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
});

const registrationRequestSchema = () =>
  Joi.object(registrationRequestRule()).error(
    validationMsg(letterValidationMsg)
  );

const cdrRequestSchema = () =>
  Joi.object(cdrRequestRule()).error(validationMsg(letterValidationMsg));
