import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import Joi from "joi";
import { FORM_ERROR } from "final-form";
import { format, isAfter, parseISO, startOfToday } from "date-fns";
import validationMsg from "../../../../helpers/validationMsg";
import {
  Divider,
  Grid,
  Stack,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Snackbar,
} from "@mui/material";
import FieldSelect from "../../../form-fields/FieldSelect";
import allLetterTypes from "../../../../data/selects/letterTypes";
import UrgentRequest from "./UrgentRequest";
import api from "../../../../apis";
import { useNavigate, useParams } from "react-router-dom";
import SubmitBtn from "../../../SubmitBtn";
import { Mail } from "@mui/icons-material";
import AccountRequest from "./AccountRequest";
import StatementRequest from "./StatementRequest";
import AmountRequest from "./AmountRequest";
import { useSelector } from "react-redux";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import AtmRequest from "./AtmRequest";
import CctvRequest from "./CctvRequest";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import ConfirmCfrRequest from "./ConfirmCfrRequest";
import {
  letterValidationMsg,
  validateLetterRules,
} from "./sub-forms/LetterForm";

const letterTypeIds = [2, 3, 4, 5, 6, 7, 8];

export default function RequestForm({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const preTypeId = params.type_id ? parseInt(params.type_id) : null;
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);
  const [bankAccounts, setBankAccounts] = useState([]);
  // const [bankOptions, setBankOptions] = useState([]);

  // snack bar
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // filter letter type follow by auth roles
  const authRoles = useSelector((state) => state.auth.user.roles);
  const letterTypes = useMemo(() => {
    return allLetterTypes.filter((lt) => {
      if (lt.dest_type !== "bank") {
        return false;
      }

      if (!lt.role) {
        return true;
      }
      return authRoles[lt.role] ? true : false;
    });
  }, [authRoles]);

  useEffect(() => {
    if (preTypeId) {
      form.change("request_type_id", preTypeId);
    }
  }, [preTypeId, form]);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  useEffect(() => {
    let active = true;

    if (!values.request_type_id) {
      active && setBankAccounts([]);
      active && setLoading(false);
      return () => {
        active = false;
      };
    }

    if ([6, 7, 8].includes(values.request_type_id)) {
      // for reqeust type which dose not need predefine acccount
      if (values.request_type_id === 8) {
        return () => (active = false);
      }

      setLoading(true);
      api
        .get(`/api/cases/${caseId}/bank_accounts`)
        .then(({ data }) => {
          if (active) {
            setBankAccounts(data.rows);
          }
        })
        .catch(() => {
          SetToastInfo({
            type: "error",
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลหมายเบื้องต้น",
          });
          if (active) {
            setBankAccounts([]);
          }
        })
        .finally(() => {
          active && setLoading(false);
        });

      return () => {
        active = false;
      };
    }

    setLoading(true);
    api
      .get(`/api/cases/${caseId}/bank_accounts_for_request`, {
        params: { request_type_id: values.request_type_id },
      })
      .then(({ data }) => {
        if (active) {
          setBankAccounts(data.rows);
        }
      })
      .catch(() => {
        SetToastInfo({
          type: "error",
          message: "เกิดข้อผิดพลาดในการดึงข้อมูลหมายเบื้องต้น",
        });
        if (active) {
          setBankAccounts([]);
        }
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [caseId, values.request_type_id]);

  // init form value when type id change
  useEffect(() => {
    if (!values.request_type_id || readOnly) {
      return undefined;
    }

    let initData = { request_type_id: values.request_type_id };
    if (form.getRegisteredFields().some((field) => field === "date")) {
      initData = { ...initData, date: format(new Date(), "yyyy-MM-dd") };
    }

    if (values.request_type_id === 8) {
      initData = { ...initData, nationality: "TH" };
    }

    if (letterTypeIds.includes(values.request_type_id)) {
      api
        .get(`/api/me`)
        .then(({ data }) => {
          initData = {
            ...initData,
            rank: data.rank,
            job_title: data.job_title,
            fname: data.fname,
            lname: data.lname,
            policestation_org_code: data.policestation_org_code?.match(
              /^[2-9]\d{4}$/
            )
              ? data.policestation_org_code
              : undefined,
            email: data.email,
            tel: data.pol_tel,
            address: data.pol_address,
            date: format(new Date(), "yyyy-MM-dd"),
          };
          form.reset(initData);
        })
        .catch(() => {
          SetToastInfo({
            type: "error",
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลหมายเบื้องต้น",
          });
        });
    } else {
      form.reset(initData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.request_type_id]);

  const bankOptions = useMemo(() => {
    const items = bankAccounts.map((item) => {
      return {
        value: item.bank?.id,
        label: `${item.bank?.name} (${item.bank?.short_name})`,
      };
    });
    return _.uniqBy(items, "value");
  }, [bankAccounts]);

  const filteredBankAccounts = useMemo(() => {
    return bankAccounts.filter((item) => item.bank_id === values.bank_id);
  }, [bankAccounts, values.bank_id]);

  const renderBankIdField = () => {
    if ([8].includes(values.request_type_id)) {
      return undefined;
    }

    if (values.request_type_id === 9) {
      // for incomplete request type which can select any bank
      return (
        <FieldAsyncSelect
          name="bank_id"
          label="ธนาคาร"
          required
          controlProp={{ sx: { width: "50%" } }}
          readOnly={readOnly}
          basePath={`/api/banks`}
          filter={{ type: "bank" }}
          itemFormat={(row) => {
            return {
              value: row.id,
              label: `${row.name} (${row.short_name})`,
            };
          }}
          inputProps={{ disableClearable: true }}
        />
      );
    }

    return (
      <FieldSelect
        name="bank_id"
        label="ธนาคาร"
        options={bankOptions}
        readOnly={readOnly}
        required
        controlProp={{ sx: { width: "50%" } }}
        disabled={loading}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid container spacing={2} justifyContent="center">
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <FieldSelect
              name="request_type_id"
              label="ประเภทคำขอ"
              options={letterTypes}
              readOnly={readOnly || preTypeId}
              required
              controlProp={{ sx: { width: "50%" } }}
              disabled={loading}
            />
            {renderBankIdField()}
            {/* <FieldSelect
              name="bank_id"
              label="ธนาคาร"
              options={bankOptions}
              readOnly={readOnly}
              required
              controlProp={{ sx: { width: "50%" } }}
              disabled={loading}
            /> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center"></Divider>
        </Grid>
        {loading ? (
          <Grid item md={12}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Grid>
        ) : (
          <>
            {values.request_type_id &&
              renderRequest(
                readOnly,
                values.request_type_id,
                filteredBankAccounts
              )}
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                {!readOnly && (
                  <SubmitBtn
                    variant="contained"
                    startIcon={<Mail />}
                    submitting={submitting}
                    pristine={pristine}
                  >
                    สร้างคำขอ
                  </SubmitBtn>
                )}
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    navigate(`/cases/${caseId}/mgt`);
                  }}
                >
                  {readOnly ? "ปิด" : "ยกเลิก"}
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

const renderRequest = (readOnly, request_type_id, bankAccounts) => {
  switch (request_type_id) {
    case 1:
      return <UrgentRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 2:
      return <AccountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 3:
      return (
        <StatementRequest readOnly={readOnly} bankAccounts={bankAccounts} />
      );
    case 4:
      return <AccountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 5:
      return <AmountRequest readOnly={readOnly} bankAccounts={bankAccounts} />;
    case 6:
      return <AtmRequest readOnly={readOnly} />;
    case 7:
      return <CctvRequest readOnly={readOnly} />;
    // case 8:
    //   return <IncompleteAccountRequest readOnly={readOnly} />;
    // case 9:
    //   return (
    //     <StatementRequest readOnly={readOnly} bankAccounts={bankAccounts} />
    //   );
    case 8:
      return <ConfirmCfrRequest readOnly={readOnly} />;
    default:
      return (
        <Grid item xs={12}>
          not support yet
        </Grid>
      );
  }
};

export const validate = (values) => {
  const errors = {};
  let vResult = null;

  // validate specific requeset type rule
  switch (values.request_type_id) {
    case 1:
      vResult = requestUrgentSchema.validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 2:
      vResult = requestAccountSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 3:
      vResult = requestStatementSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 4:
      vResult = requestAccountSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 5:
      vResult = requestFreezeSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 6:
      vResult = requestAtmSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 7:
      vResult = requestCctvSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    case 8:
      vResult = requestConfirmCfrSchema().validate(values, {
        abortEarly: false,
        allowUnknown: false,
      });
      break;
    // case 9:
    //   vResult = requestIncompleteSchema().validate(values, {
    //     abortEarly: false,
    //     allowUnknown: false,
    //   });
    //   break;
    // case 10:
    //   vResult = requestStatementSchema().validate(values, {
    //     abortEarly: false,
    //     allowUnknown: false,
    //   });
    //   break;
    default:
      errors.request_type_id = values.request_type_id
        ? "not support"
        : "โปรดเลือก";
      return errors;
  }

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    values.date &&
    !errors.date &&
    isAfter(parseISO(values.date), new Date())
  ) {
    errors.date = "มากกว่าเวลาปัจจุบัน";
  }

  if (
    values.signature &&
    !errors.signature &&
    values.signature instanceof File &&
    !values.signature.type?.startsWith("image/")
  ) {
    errors.signature = "รูปภาพเท่านั้น";
  }

  // console.log(errors);

  return errors;
};

const validateCommonRules = {
  request_type_id: Joi.number().min(1).max(9).required(),
  bank_id: Joi.number().min(1).required(),
};

const requestUrgentValidateRule = {
  ...validateCommonRules,
  bank_accounts: Joi.array()
    .length(1)
    .items(Joi.object({ bank_account_id: Joi.number().min(1).required() }))
    .required(),
};

const requestAccountValidateRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_accounts: Joi.array()
    .min(1)
    .items(Joi.object({ bank_account_id: Joi.number().min(1).required() }))
    .required(),
});

const requestStatementValidationRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_accounts: Joi.array()
    .length(1)
    .items(
      Joi.object({
        bank_account_id: Joi.number().min(1).required(),
        from_date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .required(),
        to_date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .required(),
      })
    )
    .required(),
});

const requestFreezeValidationRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_accounts: Joi.array()
    .min(1)
    .max(200)
    .items(
      Joi.object({
        bank_account_id: Joi.number().min(1).required(),
        amount: Joi.number().min(0).required(),
      })
    )
    .required(),
});

const requestAtmValidationRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_accounts: Joi.array()
    .min(1)
    .max(200)
    .items(
      Joi.object({
        bank_account_id: Joi.number().min(1).required(),
        atm_machine_no: Joi.string()
          .pattern(/^[\x20-\x7e]{4,}$/)
          .max(30)
          .required(),
        amount: Joi.number().min(0).required(),
        withdraw_at: Joi.date()
          .min(defaultJoiDates.minDateTime)
          .max(new Date())
          .required(),
      })
    )
    .required(),
});

const requestCctvValidationRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_accounts: Joi.array()
    .min(1)
    .max(200)
    .items(
      Joi.object({
        bank_account_id: Joi.number().min(1).required(),
        from_date: Joi.date()
          .min(defaultJoiDates.minDateTime)
          .max(new Date())
          .required(),
        to_date: Joi.date()
          .min(defaultJoiDates.minDateTime)
          .max(new Date())
          .required(),
        branch_name: Joi.string().max(200).required(),
      })
    )
    .required(),
});

// const requestIncompleteValidationRule = () => ({
//   ...validateCommonRules,
//   ...validateLetterRules(),
//   bank_accounts: Joi.array()
//     .min(1)
//     .max(200)
//     .items(
//       Joi.object({
//         number: Joi.string()
//           .pattern(/^[x\d]{10,}$/)
//           .max(12)
//           .required(),
//         name: Joi.string().max(255).required(),
//         transfered_at_date: Joi.date()
//           .min(defaultJoiDates.minDateTime)
//           .max(new Date())
//           .required(),
//         src_bank_id: Joi.number().required(),
//         src_number: Joi.string()
//           .pattern(/^\d{10,}$/)
//           .max(12)
//           .required(),
//         src_name: Joi.string().max(255).required(),
//         amount: Joi.number().min(0).required(),
//       })
//     )
//     .required(),
// });

const requestConfirmCfrRule = () => ({
  ...validateCommonRules,
  ...validateLetterRules(),
  bank_case_id: Joi.string()
    .pattern(/^[A-Z,0-9]{5,}$/)
    .max(100)
    .required(),
  victim_card_id: Joi.string().required(),
  nationality: Joi.string().uppercase().length(2).required(),
  is_foreigner: Joi.boolean().allow(null),
});

const requestUrgentSchema = Joi.object(requestUrgentValidateRule).error(
  validationMsg()
);

const requestAccountSchema = () =>
  Joi.object(requestAccountValidateRule()).error(
    validationMsg(letterValidationMsg)
  );

const requestStatementSchema = () =>
  Joi.object(requestStatementValidationRule()).error(
    validationMsg(letterValidationMsg)
  );

const requestFreezeSchema = () =>
  Joi.object(requestFreezeValidationRule()).error(
    validationMsg(letterValidationMsg)
  );

const requestAtmSchema = () =>
  Joi.object(requestAtmValidationRule()).error(
    validationMsg(letterValidationMsg)
  );

const requestCctvSchema = () =>
  Joi.object(requestCctvValidationRule()).error(
    validationMsg(letterValidationMsg)
  );

// const requestIncompleteSchema = () =>
//   Joi.object(requestIncompleteValidationRule()).error(
//     validationMsg(letterValidationMsg)
//   );

const requestConfirmCfrSchema = () =>
  Joi.object(requestConfirmCfrRule())
    .when(Joi.object({ is_foreigner: Joi.valid(true) }).unknown(), {
      then: Joi.object({
        victim_card_id: Joi.string()
          .min(4)
          .max(30)
          .pattern(/^[A-Z0-9]+$/)
          .required(),
      }),
      otherwise: Joi.object({
        victim_card_id: Joi.string()
          .length(13)
          .pattern(/^[0-9]{13}$/)
          .required(),
      }),
    })
    .error(validationMsg(letterValidationMsg));
