import React from "react";
import { Paper, Typography, Link, Box } from "@mui/material";
import { Form } from "react-final-form";
import AccountAddCsvForm, { validate } from "./AccountAddCsvForm";
import { useParams } from "react-router-dom";
import api from "../../../../apis";
import { useDispatch } from "react-redux";
import { forceReload as reloadAccountList } from "../../../../ducks/accountList";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import fileDownload from "js-file-download";

export default function AccountAddCsvView({ handleCancel, handleResult }) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    return api
      .post(`/api/cases/${caseId}/import_bulk_bank_account`, formValues, {
        validateStatus: (status) => true,
      })
      .then((response) => {
        const { status, data } = response;

        if (
          (status >= 200 && status < 300) ||
          (status === 500 && data.unsave_bank_accounts)
        ) {
          dispatch(reloadAccountList());
          handleCancel();
          handleResult(response);
          return;
        }

        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        }

        throw new Error();
      })
      .catch(() => {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      });
  };

  const handleDownload = () => {
    fileDownload(
      "bank,number,name,tpo_case_id\nKBNK,08738327224,กห หกด,T670493834",
      "template.csv"
    );
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        เพิ่มบัญชี
      </Typography>
      <Box sx={{ mb: 1 }}>
        <Link href="#" onClick={handleDownload}>
          template.csv
        </Link>
      </Box>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          component={AccountAddCsvForm}
          handleCancel={handleCancel}
        />
      </Paper>
    </>
  );
}
