import { Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { caseClearResult, caseEdit, caseFetch } from "../../../ducks/case";
import CaseForm, { validate, validateRules } from "./CaseForm";
import _ from "lodash";
import { FORM_ERROR } from "final-form";

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules()));
};

export default function CaseView() {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();
  const [loading, SetLoading] = useState(true);

  const item = useSelector((state) => state.case.data[caseId]);

  const [editMode, setEditMode] = useState(false);

  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    dispatch(caseFetch(caseId))
      .then(() => isSubscribed && SetLoading(false))
      .catch(({ response }) => {
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
      dispatch(caseClearResult());
    };
  }, [dispatch, caseId]);

  const onSubmit = (formData, form) => {
    return dispatch(caseEdit(caseId, formData))
      .then(() => {
        setEditMode(false);
        form.reset(formData);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      {loading ? (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      ) : (
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          id="case-info"
        >
          <Typography gutterBottom variant="h6">
            ข้อมูลเบื้องต้น{" "}
            {!editMode && (
              <Button
                size="small"
                onClick={() => setEditMode(true)}
                startIcon={<Edit />}
              >
                แก้ไข
              </Button>
            )}
          </Typography>
          <Form
            // validate={validate}
            onSubmit={onSubmit}
            initialValues={mapToInit(item)}
            component={CaseForm}
            readOnly={!editMode}
            onCancel={(form) => {
              form.restart();
              setEditMode(false);
            }}
            isTpo={item?.is_tpo || false}
            validate={validate}
          />
        </Paper>
      )}
    </>
  );
}
