import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import api from "../../../apis";

const FieldComponent = ({
  filter = {}, // my custom
  localFilter, // my custom
  label,
  required = false,
  readOnly = false,
  controlProp = {},
  inputProps = {},
  basePath,
  itemFormat,
  input,
  meta,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [fetching, SetFetching] = useState(false);

  // store object value from server
  const itemObj = useRef(null);

  if (!input.value) {
    itemObj.current = null;
  }

  const isLoadOption = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!isLoadOption) {
      return undefined;
    }

    SetFetching(true);

    (async () => {
      api
        .get(basePath, { params: { ...filter } })
        .then(({ data }) => {
          if (active) {
            let options = localFilter
              ? data.rows.filter((r) => localFilter(r))
              : data.rows;

            options = options.map((item) => {
              return itemFormat(item);
            });

            // options.push({ value: null, label: "เพิ่มบัญชี" });
            setOptions(options);
          }
        })
        .catch(() => {
          setOptions([]);
        })
        .finally(() => {
          SetFetching(false);
        });
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, isLoadOption, itemFormat]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // console.log("itemObj");
  // console.log(itemObj.current);

  // console.log("value");
  // console.log(input.value);

  //for inital value
  useEffect(() => {
    let active = true;
    // if (itemObj.current || fetching) {
    //   return undefined;
    // }
    if (meta.initial && meta.pristine) {
      if (itemObj.current && itemObj.current.value === meta.initial) {
        return undefined;
      }
      SetFetching(true);
      api
        .get(`${basePath}/${meta.initial}`)
        .then(({ data }) => {
          itemObj.current = itemFormat(data);
        })
        .catch((e) => console.log(e))
        .finally(() => active && SetFetching(false));
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, meta.initial, meta.pristine]);

  return (
    <FormControl
      variant="outlined"
      error={(meta.error || meta.submitError) && meta.touched}
      {...controlProp}
    >
      <InputLabel
        id={`${input.name}-select-label`}
        // required={required}
        error={(meta.error || meta.submitError) && meta.touched}
      ></InputLabel>
      <Autocomplete
        readOnly={readOnly}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={(event) => {
          setOpen(false);
          input.onBlur();
        }}
        options={options}
        loading={fetching}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        value={itemObj.current}
        onChange={(event, value) => {
          itemObj.current = value;
          input.onChange(value);
        }}
        // filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              //     label="Asynchronous"
              label={`${label}${required ? " *" : ""}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {fetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              error={(meta.error || meta.submitError) && meta.touched}
            />
            {(meta.error ||
              (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
              meta.touched && (
                <FormHelperText id={`${input.name}-error`} variant="outlined">
                  {meta.error || meta.submitError}
                </FormHelperText>
              )}
          </>
        )}
        {...inputProps}
      />
    </FormControl>
  );
};

FieldComponent.propTypes = {
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemFormat: PropTypes.func.isRequired,
};

export default FieldComponent;
