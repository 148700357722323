import { Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import _ from "lodash";
import ProfileForm, { validateRules, validate } from "./ProfileForm";
import api from "../../../apis";
import { FORM_ERROR } from "final-form";
import roles from "../../../data/userRoles";
import RoleInfoCard from "./RoleInfoCard";
import { useQueryClient } from "react-query";
import LineConnectDialog from "../LineConnectDialog";

export default function ProfileViewEditPage() {
  const [item, setItem] = useState({});
  const [editMode, setEditMode] = useState(false);
  const queryClient = useQueryClient();

  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`api/me`)
      .then(({ data }) => {
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = (formData, form) => {
    return api
      .put("api/me", formData)
      .then(() => {
        setEditMode(false);
        form.reset(formData);
        queryClient.invalidateQueries("me");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const roleCards = useMemo(
    () =>
      item?.roles
        ?.filter((r) => roles[r.name])
        .map((r) => {
          const roleObj = roles[r.name];
          const scopeOrgCode = roleObj.scopeOrgKey
            ? item[roleObj.scopeOrgKey]
            : undefined;
          return (
            <RoleInfoCard
              key={r.id}
              name={roleObj.name}
              desc={roleObj.desc}
              orgCode={scopeOrgCode}
            />
          );
        }),
    [item]
  );

  const [lineOpen, setLineOpen] = React.useState(false);
  const handleLineConnect = () => {
    setLineOpen(true);
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* <AccountCircle fontSize="large" /> */}
          <Typography variant="h5">ข้อมูลผู้ใช้ </Typography>
          {!editMode && (
            <Button
              size="large"
              onClick={() => setEditMode(true)}
              startIcon={<Edit fontSize="inherit" />}
            >
              แก้ไข
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Form
              onSubmit={onSubmit}
              initialValues={mapToInit(item)}
              component={ProfileForm}
              readOnly={!editMode}
              onCancel={(form) => {
                form.restart();
                setEditMode(false);
              }}
              validate={validate}
            />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">ข้อมูลสิทธิ์การใช้งาน</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  spacing={2}
                >
                  {roleCards}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <LineConnectDialog
              open={lineOpen}
              link={item?.line_notify_link}
              onClose={() => setLineOpen(false)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">อื่นๆ</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", sm: "column", md: "row" }}
                  spacing={2}
                >
                  <Card variant="outlined" sx={{ maxWidth: 300 }}>
                    <CardContent>
                      <Box sx={{ display: "flex", gap: "10px", minWidth: 250 }}>
                        <Box
                          component="img"
                          src={`${process.env.PUBLIC_URL}/images/line-icon.png`}
                          alt="Logo"
                          height="23px"
                        />
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontWeight: "bold", flexGrow: 1 }}
                          gutterBottom
                        >
                          Line
                        </Typography>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleLineConnect}
                        >
                          {item?.notify_token ? "เชื่อมต่อใหม่?" : "เชื่อมต่อ"}
                        </Link>
                      </Box>
                      <Typography
                        sx={{ mb: 1.5 }}
                        color={
                          item?.notify_token ? "success.main" : "warning.main"
                        }
                      >
                        {item?.notify_token
                          ? "เชื่อมต่อแล้ว"
                          : "ยังไม่เชื่อมต่อ"}
                      </Typography>
                      <Typography variant="body2">
                        รับการแจ้งเตือนผ่าน line
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </>
  );
}

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};
