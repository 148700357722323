import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../css/dropzone.css";
import { Close, UploadFile } from "@mui/icons-material";

const FieldComponent = ({
  name,
  required = false,
  readOnly = false,
  resizeImage,
  shouldResizeImage = () => true,
  dropZoneProps = {},
  showFileSize = true,
  placeholder,
  accept = "image/jpeg, image/png,application/pdf,application/zip",
  input,
  meta,
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = (dropFiles) => {
    input.onBlur();
    if (!dropFiles[0]) {
      return;
    }

    if (
      resizeImage &&
      dropFiles[0].type?.startsWith("image/") &&
      shouldResizeImage(dropFiles[0])
    ) {
      setLoading(true);

      resizeImage(dropFiles[0])
        .then((resizeFile) => {
          input.onChange(resizeFile);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    input.onChange(dropFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept,
    multiple: false,
    ...dropZoneProps,
    disabled: loading,
  });

  // const [pimg, setPimg] = useState();
  // useEffect(() => {
  //   (async () => {
  //     if (!input.value) {
  //       return undefined;
  //     }
  //     console.log(input.value);
  //     const signatureBase64 = await signatureResize(input.value);
  //     setPimg(signatureBase64);
  //   })();
  // }, [input.value]);

  const preview = useMemo(() => {
    if (loading) {
      return (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress />
            </Box>
            <CardContent>
              <Typography
                variant="subtitle2"
                component="div"
                textAlign={"center"}
                noWrap
              >
                Loading...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }

    if (!input.value) {
      return undefined;
    }

    const fileSizeMB = input.value?.size
      ? (input.value?.size / 1048576).toFixed(2)
      : null;

    return (
      <>
        <Card sx={{ maxWidth: 345 }} style={{ position: "relative" }}>
          <Box
            sx={{
              display: "block",
              position: "absolute",
            }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                input.onChange(undefined);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
          {input.value.type?.startsWith("image/") ? (
            <CardMedia
              component="img"
              height="140"
              image={URL.createObjectURL(input.value)}
              alt="upload file"
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <UploadFile sx={{ fontSize: 80, mt: 3 }} />
            </Box>
          )}
          <CardContent>
            <Typography
              variant="subtitle2"
              component="div"
              textAlign={"center"}
              noWrap
            >
              {input.value.name || "upload file"}
            </Typography>
            {showFileSize && fileSizeMB && (
              <Typography
                variant="caption"
                component="div"
                textAlign={"center"}
                noWrap
              >
                ({fileSizeMB} MB)
              </Typography>
            )}
          </CardContent>
        </Card>
      </>
    );
  }, [input, loading, showFileSize]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        {meta.invalid && meta.touched && (
          <Typography variant="caption" color="error.main">
            {meta.error || meta.submitError}
          </Typography>
        )}
        {preview}
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div
            className="text-wrap"
            style={{ width: "100%", textAlign: "center" }}
          >
            {placeholder || (
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.jpg,*.gif,*.png,*pdf,*zip) only
              </>
            )}
          </div>
        </div>
        {/* {pimg && (
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={pimg}
                alt="upload file"
              />
            </CardActionArea>
          </Card>
        )} */}
      </Stack>
    </>
  );
};

export default FieldComponent;

// const signatureResize = (file) => {
//   const fileType = file.type === "image/png" ? "PNG" : "JPEG";
//   const compress = file.type === "image/png" ? 100 : 90;
//   return new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       90,
//       50,
//       fileType,
//       compress,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "base64"
//     );
//   });
// };
