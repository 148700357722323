import {
  AccountBalance,
  Delete,
  ForwardToInbox,
  SendToMobile,
  Visibility,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  crqClearResult,
  crqMarkLoading,
  crqFetch,
  crqSetFilter,
} from "../../../ducks/caseRequestList";
import _ from "lodash";
import { getChip } from "../../../data/requestStatus";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import api from "../../../apis";
import { forceReload as forceReloadRequests } from "../../../ducks/caseRequestList";
import { format, isValid, parseISO } from "date-fns";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RequestListView() {
  const params = useParams();
  const caseId = parseInt(params.id);

  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack
            spacing={1}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            <IconButton
              component={Link}
              to={`/cases/${caseId}/requests/${params.id}`}
              type="button"
              variant="contained"
              size="small"
              // onClick={() => {
              //   handleOpenModal(
              //     <RequestView
              //       handleCancel={handleCloseModal}
              //       requestId={params.id}
              //     />
              //   );
              // }}
            >
              {params.row.status === "new" ? (
                <ForwardToInbox color="primary" />
              ) : (
                <Visibility />
              )}
            </IconButton>
            {["new", "reject"].includes(params.row.status) && (
              <IconButton
                type="button"
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  setCfDel(params.id);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
    { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
            {params.row.status === "new" && (
              <Chip label="กรุณายืนยัน" color="warning" size="small" />
            )}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.created_at) {
          return undefined;
        }

        const date = parseISO(params.row.created_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
    {
      field: "description",
      headerName: "ข้อมูลที่ร้องขอ",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
    {
      field: "status_note",
      headerName: "หมายเหตุ",
      sortable: false,
      minWidth: 200,
      maxWidth: 250,
    },
  ];

  const dispatch = useDispatch();
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    return () => {
      dispatch(crqClearResult());
      dispatch(crqSetFilter({}));
    };
  }, [dispatch]);

  const rows = useSelector((state) => state.caseRequestList.data);
  const total = useSelector((state) => state.caseRequestList.meta.total);
  const loading = useSelector((state) => state.caseRequestList.meta.loading);
  const forceReload = useSelector(
    (state) => state.caseRequestList.meta.forceReload
  );
  // const filter = useSelector((state) => state.caseRequestList.meta.filter);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(crqMarkLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(
      crqFetch(
        rowsState.pageSize,
        rowsState.page,
        undefined,
        fetchId,
        fetchIdRef,
        caseId
      )
    ).catch(({ response }) => {
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      dispatch(crqClearResult());
      dispatch(crqMarkLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rowsState.page, rowsState.pageSize, forceReload]);

  // add request modal
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/cases/${caseId}/pol_requests/${cfDel}`)
      .then(() => {
        dispatch(forceReloadRequests());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      id="letter"
    >
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ รายการคำขอ (ID: {cfDel})
      </ConfirmDialog>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Typography gutterBottom variant="h6">
        รายการคำขอ
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Button
          component={Link}
          to={`/cases/${caseId}/requests/add`}
          variant="contained"
          color="success"
          startIcon={<AccountBalance />}
        >
          เพิ่มคำขอธนาคาร
        </Button>
        <Button
          component={Link}
          to={`/cases/${caseId}/requests-telco/add`}
          variant="contained"
          color="success"
          startIcon={<SendToMobile />}
        >
          เพิ่มคำขอโทรศัพท์
        </Button>
      </Stack>
      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          disableSelectionOnClick
          disableColumnMenu
          columns={columns}
          rows={rows}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 20]}
          pagination
          {...rowsState}
          paginationMode="server"
          onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
          onPageSizeChange={(pageSize) =>
            setRowsState((prev) => ({ ...prev, page: 0, pageSize }))
          }
        />
      </div>
    </Paper>
  );
}
