import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import SubmitBtn from "../../../SubmitBtn";
import { Save } from "@mui/icons-material";
import FieldInput from "../../../form-fields/FieldInput";
import _ from "lodash";
import validationMsg from "../../../../helpers/validationMsg";

export default function AccountAddCsvForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldInput
            name="bank_accounts_csv"
            label="Csv Content"
            controlProps={{ fullWidth: true }}
            inputProps={{
              multiline: true,
              maxRows: 6,
              minRows: 3,
              placeholder: "วางเนื้อหา csv ที่นี่",
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  bank_accounts_csv: Joi.string().required(),
};

const schema = Joi.object(validateRules).error(validationMsg());
