import { CheckCircle } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import FieldAccountFreeze from "../../../form-fields/customs/FieldAccountFreeze";
import {
  isNumeric,
  thousands_separators,
} from "../../../../helpers/stringFormater";
import LetterForm from "./sub-forms/LetterForm";
import bankAccountTypes from "../../../../data/selects/bankAccountTypes";

export default function AmountRequest({ readOnly, bankAccounts = [] }) {
  const [pageSize, setPageSize] = useState(10);

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "bank.name",
        headerName: "ธนาคาร",
        sortable: false,
        width: 100,
        valueGetter: (params) => {
          return params.row.bank?.short_name;
        },
      },
      {
        field: "number",
        headerName: "เลขบัญชี",
        sortable: false,
        minWidth: 200,
        maxWidth: 400,
      },
      {
        field: "name",
        headerName: "ชื่อบัญชี",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        valueGetter: (params) => {
          return (
            params.row.name + (params.row.note ? `(${params.row.note})` : "")
          );
        },
      },
      {
        field: "type",
        headerName: "ประเภท",
        sortable: false,
        minWidth: 80,
        maxWidth: 100,
        valueGetter: ({ row }) => {
          const accType = bankAccountTypes.find((e) => e.value === row.type_id);
          return accType?.label;
        },
      },
      {
        field: "amount",
        headerName: "จำนวน (บาท)",
        sortable: false,
        minWidth: 150,
        maxWidth: 180,
        valueFormatter: ({ value }) => {
          if (!value || !isNumeric(value)) {
            return "";
          }
          return thousands_separators(value);
        },
      },
      {
        field: "is_requested",
        headerName: "ส่งคำขอ",
        sortable: false,
        minWidth: 60,
        maxWidth: 100,
        renderCell: (params) => {
          return params.row.is_requested ? <CheckCircle color="success" /> : "";
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          {readOnly ? "บัญชี" : "เลือกบัญชี"}
        </Typography>
        <FieldAccountFreeze
          name="bank_accounts"
          disableColumnMenu
          columns={columns}
          rows={bankAccounts}
          // loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          // pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          getRowHeight={() => "auto"}
          readOnly={readOnly}
          initialState={{
            columns: {
              columnVisibilityModel: {
                is_requested: !readOnly,
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center"></Divider>
      </Grid>
      <LetterForm readOnly={readOnly} />
    </>
  );
}
