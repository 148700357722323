import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { isNumeric } from "../../helpers/stringFormater";

export default function FieldInput({
  name,
  inputType,
  label,
  required = false,
  readOnly = false,
  numericFormater,
  controlProps = {},
  inputProps = {},
  inputLabelProps = {},
  showHelperTextReadOnly = false,
  helperText,
  helperTextProps = {},
}) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl variant="outlined" {...controlProps}>
          <InputLabel
            htmlFor={name}
            error={(meta.error || meta.submitError) && meta.touched}
            required={required}
            {...inputLabelProps}
          >
            {label}
          </InputLabel>
          {readOnly ? (
            <>
              <OutlinedInput
                {...inputProps}
                type={inputType || "text"}
                id={name}
                label={`${label}${required ? "*" : ""}`}
                readOnly={readOnly}
                value={
                  numericFormater && isNumeric(input.value)
                    ? numericFormater(input.value)
                    : input.value
                }
              />
              {helperText && showHelperTextReadOnly && (
                <FormHelperText
                  id={`${name}-helper`}
                  variant="outlined"
                  {...helperTextProps}
                >
                  {helperText}
                </FormHelperText>
              )}
            </>
          ) : (
            <>
              <OutlinedInput
                type={inputType || "text"}
                id={name}
                label={`${label}${required ? "*" : ""}`}
                error={(meta.error || meta.submitError) && meta.touched}
                {...inputProps}
                // required={required}
                {...input}
              />
              {(meta.error ||
                (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
              meta.touched ? (
                <FormHelperText error id={`${name}-error`} variant="outlined">
                  {meta.error || meta.submitError}
                </FormHelperText>
              ) : (
                helperText && (
                  <FormHelperText
                    id={`${name}-helper`}
                    variant="outlined"
                    {...helperTextProps}
                  >
                    {helperText}
                  </FormHelperText>
                )
              )}
            </>
          )}
        </FormControl>
      )}
    </Field>
  );
}
