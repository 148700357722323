import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import { getTextMask } from "../../../helpers/imask";

const TextMaskCustom = getTextMask({
  mask: "00000000000000",
  unmask: true,
  overwrite: true,
});

export default function AccountForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath={`/api/banks`}
            filter={{ type: "bank" }}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
            inputProps={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="number"
            label="หมายเลขบัญชี"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{
              inputComponent: TextMaskCustom,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="name"
            label="ชื่อบัญชี"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภทบัญชี"
            options={bankAccountTypes}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            // required
            // disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="seq"
            label="ลำดับการรับเงิน"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputType="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="freeze_status"
            label="สถานะการอายัด"
            options={[{ value: 1, label: "อายัด" }]}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            // required
            // disabled={loading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            inputType="number"
            name="balance"
            label="ยอดเงินคงเหลือ"
            controlProps={{ fullWidth: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประจำตัวประชาชน หรือ passport"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="tel"
            label="หมายเลขโทรศัพท์"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid> */}
        <Grid item xs={12}>
          <FieldInput
            name="note"
            label="หมายเหตุ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  number: Joi.string()
    .pattern(/^\d{10,}$/)
    .max(14)
    .required(),
  bank_id: Joi.number().required(),
  name: Joi.string().max(255).allow(null),
  type_id: Joi.number().allow(null),
  seq: Joi.number().min(0).allow(null),
  // balance: Joi.number().min(0).allow(null),
  // freeze_status: Joi.number().allow(null, 1),
  // tel: Joi.string().allow(null),
  // card_id: Joi.string().max(100).allow(null),
  note: Joi.string().max(20).allow(null),
};

const schema = Joi.object(validateRules).error(validationMsg());
