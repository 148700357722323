import axios from "axios";
import _ from "lodash";
import { init, maintenance } from "../ducks/appData";
import { authLogout } from "../ducks/auth";

let store = null;
const mainAxios = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  baseURL: window.location.origin + (process.env.REACT_APP_BASE_URL || ""),
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

mainAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (originalRequest.url === "/api/login") {
      return Promise.reject(error);
    }
    if (error.response?.status === 401) {
      store.dispatch(authLogout());
    }

    if (error.response?.status === 503) {
      store.dispatch(maintenance(error.response.data?.message));
    }

    return Promise.reject(error);
  }
);

export const initApi = (reduxStore) => {
  store = reduxStore;
  store.dispatch(init());
};

export default mainAxios;

export const errorResponse = (error) => {
  return _.pick(error.response, ["status", "statusText", "data"]);
};

export const setAuthorizationToken = (token) => {
  if (token) {
    mainAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete mainAxios.defaults.headers.common["Authorization"];
  }
};
