import { Search as SearchIcon } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import Joi from "joi";
import _ from "lodash";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import hrStatusSelect from "../../../data/selects/hr03Status";
import { banksOptionsQuery } from "../../../data/queries/banks";
import { useQuery } from "react-query";

function validate(values, props) {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });
  // console.log(vResult);
  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors._error = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
}

const schema = Joi.object({
  hr_type: Joi.number().valid(1, 2).allow(null),
  // policestation_org_code: Joi.object({
  //   org_code: Joi.string()
  //     .pattern(/^\d{5}$/)
  //     .required(),
  //   org_type: Joi.string().uppercase().length(2).required(),
  // }),
  bank_id: Joi.number().allow(null),
  // start_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  // end_date: Joi.date()
  //   .min(defaultJoiDates.minDate)
  //   .max(defaultJoiDates.forceUTC(startOfToday()))
  //   .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
});

export default function Hr03AccountSearchForm({ onSubmit, loading, onReset }) {
  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(banksOptionsQuery());

  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({
        handleSubmit,
        error,
        submitting,
        pristine,
        initialValues,
        submitFailed,
        form,
        values,
        modifiedSinceLastSubmit,
      }) => {
        //     console.log(values);
        //     console.log(submitting);
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldAutoComplete
                  name="hr_type"
                  label="ประเภท"
                  options={[
                    {
                      value: 1,
                      label: "HR03-1",
                    },
                    {
                      value: 2,
                      label: "HR03-2",
                    },
                  ]}
                  controlProp={{ sx: { width: "100%" } }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldAutoComplete
                  name="status"
                  label="สถานะ"
                  options={hrStatusSelect}
                  controlProp={{
                    sx: {
                      width: "100%",
                    },
                  }}
                  // inputProps={{ sx: { fullWidth: true } }}
                  // controlProp={{ sx: { fullWidth: true } }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FieldAutoComplete
                  name="bank_id"
                  label="ธนาคาร"
                  required
                  controlProp={{ fullWidth: true }}
                  inputProps={{
                    sx: { width: "100%" },
                    loading:
                      isBankOptionsLoading || bankOptionsError ? true : false,
                    loadingText: bankOptionsError ? "Error" : "Loading…",
                  }}
                  options={bankOptions}
                />
              </Grid>
              {/* <Grid item xs={12} xl={4}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            spacing={2}
          >
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Stack>
        </Grid> */}
              <Grid item xs={12} lg={4} xl={3}>
                <FieldInput
                  name="search"
                  label="คำค้นหา"
                  controlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <SubmitBtn
                    variant="contained"
                    startIcon={<SearchIcon />}
                    submitting={loading}
                    pristine={pristine && !modifiedSinceLastSubmit}
                  >
                    ค้นหา
                  </SubmitBtn>
                  <Button
                    variant="outlined"
                    disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                    onClick={(event) => {
                      form.reset();
                      if (onReset) onReset(event);
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}
