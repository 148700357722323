import { Check } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Joi from "joi";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import SubmitBtn from "../../../SubmitBtn";
import FieldInput from "../../FieldInput";
import validationMsg from "../../../../helpers/validationMsg";
import _ from "lodash";
import { FORM_ERROR } from "final-form";
import FieldDateTimePicker from "../../FieldDateTimePicker";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import { useSelector } from "react-redux";
import FieldAutoComplete from "../../FieldAutoComplete";

export default function MachineForm({
  onSubmit,
  handleCloseModal,
  header,
  initialValues,
}) {
  const reqBank = useSelector((state) => state.req18f.reqBank);
  const preAccounts = useSelector((state) => state.req18f.preAccounts);

  const preAccountOptions = useMemo(
    () =>
      preAccounts.map((e) => ({
        value: e.id,
        label: `${e.bank.short_name}:${e.number}:${e.name}`,
      })),
    [preAccounts]
  );

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {header}
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  {submitFailed && (error || submitError) && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ width: "auto" }}>
                        {error || submitError}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="info.main"
                      gutterBottom
                    >
                      {`${reqBank.name}(${reqBank.short_name})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldAutoComplete
                      name="bank_account_id"
                      label="บัญชี"
                      required
                      controlProp={{ fullWidth: true }}
                      inputProps={{ sx: { width: "100%" } }}
                      options={preAccountOptions}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldInput
                      name="atm_machine_no"
                      label="หมายเลขตู้"
                      required
                      controlProps={{ fullWidth: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldDateTimePicker
                      name="withdraw_at"
                      label="วันที่ เวลา"
                      required
                      pickerProps={{
                        inputFormat: "dd/MM/yyyy HH:mm",
                        disableFuture: true,
                        openTo: "year",
                        views: ["month", "day", "hours", "minutes"],
                        allowSameDateSelection: true,
                      }}
                      storeFormat="yyyy-MM-dd HH:mm:00"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldInput
                      inputType="number"
                      name="amount"
                      label="จำนวน(บาท)"
                      required
                      controlProps={{ fullWidth: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item md={12}>
                    <Stack direction="row" spacing={2}>
                      <SubmitBtn
                        variant="contained"
                        startIcon={<Check />}
                        submitting={submitting}
                        pristine={pristine}
                      >
                        ตกลง
                      </SubmitBtn>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={submitting}
                        onClick={() => {
                          handleCloseModal();
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Paper>
    </>
  );
}

const validate = (values) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = () => ({
  bank_account_id: Joi.number().min(1).required(),
  atm_machine_no: Joi.string()
    .pattern(/^[\x20-\x7e]{4,}$/)
    .max(30)
    .required(),
  amount: Joi.number().min(0).required(),
  withdraw_at: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(new Date())
    .required(),
});

const schema = () =>
  Joi.object(validateRules()).error(
    validationMsg({
      atm_machine_no: { stringPattern: "อักษรอังกฤษ, ตัวเลข หรือเครื่องหมาย" },
      dateFormat: { withdraw_at: "dd/MM/yyyy HH:mm" },
    })
  );
