import { Add, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Snackbar,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { markLoading, clearResult, fetch } from "../../../ducks/accountList";
import _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import AccountAddPage from "./AccountAddPage";
import AccountEditPage from "./AccountEditPage";
import RequireRoles from "../../RequireRoles";
import AccountAddCsvView from "./add-csv/AccountAddCsvView";
import AccountAddCsvResultView from "./add-csv/AccountAddCsvResultView";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AccountList() {
  const params = useParams();
  const caseId = parseInt(params.id);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const dispatch = useDispatch();
  // bank account table
  const fetchIdRef = React.useRef(0);
  const [pageSize, setPageSize] = React.useState(5);

  const rows = useSelector((state) => state.accountList.data);
  // const total = useSelector((state) => state.accountList.meta.total);
  const loading = useSelector((state) => state.accountList.meta.loading);
  // const filter = useSelector((state) => state.accountList.meta.filter);
  const forceReload = useSelector(
    (state) => state.accountList.meta.forceReload
  );

  // add account modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const columns = [
    {
      field: "action",
      headerName: "actions",
      sortable: false,
      minWidth: 50,
      maxWidth: 80,
      renderCell: (params) => {
        return (
          <Stack
            spacing={1}
            direction="row"
            // sx={{ width: "100%" }}
            // justifyContent="center"
          >
            <IconButton
              type="button"
              variant="contained"
              size="small"
              onClick={() => {
                handleOpenModal(
                  <AccountEditPage
                    handleCancel={handleCloseModal}
                    accountId={params.id}
                  />
                );
              }}
            >
              <Edit />
            </IconButton>
            {/* <IconButton
              type="button"
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                SetCfDel({
                  id: params.id,
                  msg: `ยืนยันการลบ Transaction (ID: ${params.id})`,
                });
              }}
            >
              <Delete />
            </IconButton> */}
          </Stack>
        );
      },
    },
    { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "number",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 400,
    },
    {
      field: "name",
      headerName: "ชื่อบัญชี",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
    },
    {
      field: "type",
      headerName: "ประเภท",
      sortable: false,
      minWidth: 80,
      maxWidth: 100,
      valueFormatter: ({ value }) => {
        return value?.type;
      },
    },
    {
      field: "freeze_status",
      headerName: "สถานะอายัด",
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: ({ value }) => {
        return value && "อายัด";
      },
    },
    {
      field: "seq",
      headerName: "ลำดับการรับเงิน",
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
    },
    // {
    //   field: "balance",
    //   headerName: "เงินคงเหลือ",
    //   sortable: false,
    //   minWidth: 100,
    //   maxWidth: 120,
    //   valueFormatter: ({ value }) => {
    //     if (!value || !isNumeric(value)) {
    //       return "";
    //     }
    //     return thousands_separators(value);
    //   },
    // },
    // {
    //   field: "card_id",
    //   headerName: "เลขประจำตัวประชาชน",
    //   sortable: false,
    //   minWidth: 150,
    //   maxWidth: 150,
    // },
    // {
    //   field: "tel",
    //   headerName: "โทรศัพท์",
    //   sortable: false,
    //   minWidth: 150,
    //   maxWidth: 150,
    // },
    // {
    //   field: "note",
    //   headerName: "หมายเหตุ",
    //   sortable: false,
    //   minWidth: 150,
    //   maxWidth: 150,
    // },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearResult());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(markLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(0, 0, {}, fetchId, fetchIdRef, caseId)).catch(
      ({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.log(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(clearResult());
        dispatch(markLoading(false));
      }
    );
  }, [dispatch, caseId, forceReload]);

  const handleAddCsvResult = (response) => {
    handleOpenModal(
      <AccountAddCsvResultView
        response={response}
        handleCancel={handleCloseModal}
      />
    );
  };

  return (
    <>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      {/* <Typography gutterBottom variant="h6">
        บัญชีธนาคาร
      </Typography> */}
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <RequireRoles roleIds={[2]}>
          <Button
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => {
              handleOpenModal(
                <AccountAddCsvView
                  handleCancel={handleCloseModal}
                  handleResult={handleAddCsvResult}
                />
              );
            }}
          >
            เพิ่มบัญชี(CSV)
          </Button>
        </RequireRoles>
        <Button
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => {
            handleOpenModal(<AccountAddPage handleCancel={handleCloseModal} />);
          }}
        >
          เพิ่มบัญชี
        </Button>
      </Stack>
      <DataGrid
        disableColumnMenu
        columns={columns}
        rows={rows}
        loading={loading}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        // pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        autoHeight
        getRowHeight={() => "auto"}
        // sx={{ height: "400px" }}
      />
    </>
  );
}
