import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { policeStationQuery } from "../../../data/queries/policestations";

export default function RoleInfoCard({ name, desc, orgCode }) {
  const scopeOrg = useQuery(policeStationQuery(orgCode));
  return (
    <Card variant="outlined" sx={{ maxWidth: 300 }}>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold" }}
          gutterBottom={!orgCode}
        >
          {name}
        </Typography>
        {orgCode && (
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {scopeOrg.isLoading ? "Loading..." : scopeOrg.data.ORG}
          </Typography>
        )}
        <Typography variant="body2">{desc}</Typography>
      </CardContent>
    </Card>
  );
}
