import api from "../apis";

export const APP_MARK_LOADING = "app/APP_MARK_LOADING";
export const APP_INIT_SUCCESS = "app/APP_INIT_SUCCESS";
export const APP_INIT_FAIL = "app/APP_INIT_FAIL";
export const APP_MA = "app/APP_MA";

const defaultState = {
  isLoading: true,
  initSuccess: null,
  maintenance: { status: false, message: null },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APP_MARK_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case APP_INIT_SUCCESS:
      return {
        ...state,
        initSuccess: true,
        isLoading: false,
      };
    case APP_INIT_FAIL:
      return {
        ...state,
        initSuccess: false,
        isLoading: false,
      };
    case APP_MA: {
      return {
        ...state,
        maintenance: { status: true, message: action.payload },
      };
    }
    default:
      return state;
  }
}

export const markLoading = (isLoading) => {
  return { type: APP_MARK_LOADING, payload: isLoading };
};

export const init = () => async (dispatch) => {
  try {
    const response = await api.get("/sanctum/csrf-cookie");
    if (response.status === 204) {
      dispatch({ type: APP_INIT_SUCCESS });
      return;
    }
    dispatch({ type: APP_INIT_FAIL });
  } catch (e) {
    dispatch({ type: APP_INIT_FAIL });
  }
};

export const maintenance = (msg) => {
  return { type: APP_MA, payload: msg };
};
