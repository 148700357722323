import { Rule, Visibility } from "@mui/icons-material";
import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  setFilter,
  forceReload as forceReloadGrid,
} from "../../../ducks/hr03MgtList";
import Hr03MgtSearchForm, { validate } from "./Hr03MgtSearchForm";
import { getChip } from "../../../data/hr03Status";
import Hr03VerifyView from "./Hr03VerifyView";
import Hr03AccountView from "../hr03/Hr03AccountView";
import { Form } from "react-final-form";
import _ from "lodash";
import { getHR03LabelById } from "../../../data/hr03Types";

export default function Hr03MgtListPage() {
  const dispatch = useDispatch();

  // let resolved = useResolvedPath("/admin/request-mgt");
  // let isAdminPath = useMatch({ path: resolved.pathname, end: false });

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.hr03Mgt.data);
  const total = useSelector((state) => state.hr03Mgt.meta.total);
  const loading = useSelector((state) => state.hr03Mgt.meta.loading);
  const forceReload = useSelector((state) => state.hr03Mgt.meta.forceReload);
  const page = useSelector((state) => state.hr03Mgt.meta.page);
  const pageSize = useSelector((state) => state.hr03Mgt.meta.pageSize);
  const filter = useSelector((state) => state.hr03Mgt.meta.filter);

  const hr03OrgName = useSelector((state) => state.auth.user.hr03Org?.ORG);

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    // const activeFilter = hr03Status
    //   ? { ...filter, status: hr03Status }
    //   : filter;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    if (payload?.toast) {
      SetToastInfo(payload.toast);
      if (payload.toast.type !== "error") {
        dispatch(forceReloadGrid());
      }
    }
    setOpenModal(false);
  };

  const renderAction = ({ row }) => {
    if (row.status === "sent_to_bh") {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            size="small"
            sx={{ boxShadow: 1 }}
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyView
                  handleCancel={handleCloseModal}
                  itemId={row.id}
                  caseId={row.case_id}
                />
              );
            }}
          >
            <Rule color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="ดูข้อมูล">
        <IconButton
          type="button"
          variant="contained"
          size="small"
          onClick={() => {
            handleOpenModal(
              <Hr03AccountView
                handleCancel={handleCloseModal}
                itemId={row.id}
              />
            );
          }}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  };

  const renderTitle = () => {
    // switch (hr03Status) {
    //   case "sent_to_bh":
    //     return (
    //       <Stack direction="row" spacing={1}>
    //         <Typography variant="h5">ตรวจสอบข้อมูล HR03</Typography>
    //         {getChip(hr03Status)}
    //       </Stack>
    //     );
    //   default:
    //     return <Typography variant="h5">รายการ HR03</Typography>;
    // }
    return <Typography variant="h5">ตรวจสอบข้อมูล HR03</Typography>;
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Stack
            spacing={0.5}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            {renderAction(params)}
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        // const onChipClick =
        //   params.row.status === "sent_to_bh"
        //     ? () => {
        //         handleOpenModal(
        //           <Hr03VerifyView
        //             handleCancel={handleCloseModal}
        //             itemId={params.row.id}
        //             caseId={params.row.case_id}
        //           />
        //         );
        //       }
        //     : undefined;

        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, {
              size: "small",
              // onClick: onChipClick,
            })}{" "}
            {params.row.status === "new" && (
              <Chip label="กรุณายืนยัน" color="warning" size="small" />
            )}
          </Box>
        );
      },
    },
    {
      field: "hr_type",
      headerName: "ประเภท",
      sortable: false,
      width: 110,
      valueFormatter: ({ value }) => {
        return getHR03LabelById(value);
      },
    },
    {
      field: "tpo_case_id",
      headerName: "caseId",
      sortable: false,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-สกุล",
      sortable: false,
      minWidth: 120,
      maxWidth: 250,
      valueGetter: ({ row }) => {
        return `${row.name} ${row.surname}`;
        // return params.row.bank?.short_name;
      },
    },
    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: false,
      width: 80,
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 150,
      maxWidth: 180,
    },
    {
      field: "pol_name",
      headerName: "ชื่อ พงส.",
      sortable: false,
      width: 150,
    },
    {
      field: "pol_policestation",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        return value.ORG_ABBR;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  const onSearchSubmit = (data) => {
    let filter = { ...data };
    if (data.policestation_org_code) {
      const { org_type, org_code } = filter.policestation_org_code;
      filter = _.omit(filter, ["policestation_org_code"]);
      switch (org_type) {
        case "BH":
          filter.bh_code = org_code;
          break;
        case "BK":
          filter.bk_code = org_code;
          break;
        default:
          filter.kk_code = org_code;
      }
    }
    dispatch(setFilter(filter));
  };

  useEffect(() => {
    dispatch(setFilter({ status: "sent_to_bh" }));
  }, [dispatch]);

  // delete record confirmation
  // const [cfDel, setCfDel] = useState(false);
  // const handleCfDelClose = () => setCfDel(false);
  // const handleCfDelOk = () => {
  //   return api
  //     .delete(`/api/nominees/${cfDel}`)
  //     .then(() => {
  //       dispatch(forceReloadGrid());
  //     })
  //     .catch(() => {
  //       SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
  //     });
  // };

  // if (!isValidStatus) {
  //   return <Navigate to="/" />;
  // }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      {/* <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ บัญชีม้า(ID: {cfDel})
      </ConfirmDialog> */}
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {renderTitle()}
        {hr03OrgName && (
          <Typography variant="subtitle1">{hr03OrgName}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSearchSubmit}
            component={Hr03MgtSearchForm}
            loading={loading}
            onReset={() => dispatch(setFilter({ status: "sent_to_bh" }))}
            showStatusField
            initialValues={{ status: "sent_to_bh" }}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
          />
        </Paper>
      </Grid>
    </>
  );
}
