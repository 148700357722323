import { useQuery } from "react-query";
import api from "../../apis";

const STALE_TIME = 20 * 60 * 1000;

export const usePoliceStationOptions = (params) =>
  useQuery(
    ["policestations", "select", params],
    () =>
      api
        .get(`/api/policestations`, {
          params: { ...params, page: 0, page_size: 350 },
        })
        .then((res) =>
          res.data.rows.map((row) => ({
            value: row.ORG_CODE,
            label: row.ORG_ABBR,
          }))
        ),
    { staleTime: STALE_TIME }
  );

export const useObjPoliceStationOptions = (params) =>
  useQuery(
    ["policestations", "select", "obj", params],
    () =>
      api
        .get(`/api/policestations`, {
          params: { ...params, page: 0, page_size: 350 },
        })
        .then((res) =>
          res.data.rows.map((row) => ({
            value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
            label: row.ORG_ABBR,
          }))
        ),
    { staleTime: STALE_TIME }
  );

export const policeStationObjOptionsQuery = (params) => ({
  queryKey: ["policestations", "select", "obj", params],
  queryFn: () =>
    api
      .get(`/api/policestations`, {
        params: { ...params, page: 0, page_size: 350 },
      })
      .then((res) =>
        res.data.rows.map((row) => ({
          value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
          label: row.ORG_ABBR,
        }))
      ),
  staleTime: Infinity,
});

export const policeStationQuery = (id) => ({
  queryKey: ["policestations", id],
  queryFn: () => api.get(`/api/policestations/${id}`).then((res) => res.data),
  staleTime: Infinity,
  enabled: !!id,
});
