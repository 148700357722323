import {
  Add,
  CheckCircle,
  Delete,
  Edit,
  PublishedWithChangesOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  setFilter,
  forceReload as forceReloadGrid,
} from "../../../ducks/hr03List";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import Hr03AccountSearchForm from "./Hr03AccountSearchForm";
import Hr03VerifyAccountView from "./Hr03VerifyAccountView";
import api from "../../../apis";
import MuiLink from "@mui/material/Link";
import { Link, useNavigate } from "react-router-dom";
import { getChip } from "../../../data/hr03Status";
import Hr03AccountView from "./Hr03AccountView";
import { getHR03LabelById } from "../../../data/hr03Types";

export default function Hr03ListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // add account modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    if (payload?.type === "error") {
      SetToastInfo(payload);
    } else {
      dispatch(forceReloadGrid());
    }
    setOpenModal(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.hr03.data);
  const total = useSelector((state) => state.hr03.meta.total);
  const loading = useSelector((state) => state.hr03.meta.loading);
  const forceReload = useSelector((state) => state.hr03.meta.forceReload);
  const page = useSelector((state) => state.hr03.meta.page);
  const pageSize = useSelector((state) => state.hr03.meta.pageSize);
  const filter = useSelector((state) => state.hr03.meta.filter);

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  const renderMainAction = (params) => {
    if (params.row.status === "new") {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyAccountView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                />
              );
            }}
          >
            <CheckCircle color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    if (["bh_reject", "rtp_reject"].includes(params.row.status)) {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyAccountView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                />
              );
            }}
          >
            <PublishedWithChangesOutlined color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title="ดูข้อมูล">
        <IconButton
          type="button"
          variant="contained"
          size="small"
          onClick={() => {
            handleOpenModal(
              <Hr03AccountView
                handleCancel={handleCloseModal}
                itemId={params.id}
              />
            );
          }}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Stack
            spacing={0.5}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            {renderMainAction(params)}
            {["new", "bh_reject", "rtp_reject"].includes(params.row.status) && (
              <>
                <Tooltip title="แก้ไข">
                  <IconButton
                    type="button"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`/hr03/edit/${params.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <IconButton
                  type="button"
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => {
                    setCfDel(params.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
            {params.row.status === "new" && (
              <Chip label="กรุณายืนยัน" color="warning" size="small" />
            )}
          </Box>
        );
      },
    },
    {
      field: "hr_type",
      headerName: "ประเภท",
      sortable: false,
      width: 80,
      valueFormatter: ({ value }) => {
        return getHR03LabelById(value);
      },
    },
    {
      field: "tpo_case_id",
      headerName: "caseId",
      sortable: false,
      minWidth: 120,
      maxWidth: 130,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: false,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-สกุล",
      sortable: false,
      minWidth: 120,
      maxWidth: 250,
      valueGetter: ({ row }) => {
        return `${row.name} ${row.surname}`;
        // return params.row.bank?.short_name;
      },
    },
    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: false,
      width: 80,
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 150,
      maxWidth: 180,
    },
    {
      field: "pol_name",
      headerName: "ชื่อ พงส.",
      sortable: false,
      width: 150,
    },
    {
      field: "pol_policestation",
      headerName: "หน่วยงาน",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        return value.ORG_ABBR;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  const onSearchFormSubmit = (data) => {
    dispatch(setFilter(data));
  };

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/hr_reports/${cfDel}`)
      .then(() => {
        dispatch(forceReloadGrid());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ(ID: {cfDel})
      </ConfirmDialog>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Typography variant="h5">บัญชี HR03</Typography>
        <Typography variant="subtitle1">
          การแจ้งรายชื่อบุคคลผู้ที่ความเสี่ยงสูง (HR03)
          เพื่อระงับการทำธุรกรรมทางอิเล็กทรอนิกส์ ทุกบัญชีของบุคคลนั้น (
          <MuiLink
            rel="noopener"
            target="_blank"
            href="https://sites.google.com/view/pctpolice/home/%E0%B8%84%E0%B8%A1%E0%B8%AD%E0%B9%81%E0%B8%88%E0%B8%87%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%8A%E0%B8%AD%E0%B8%9A%E0%B8%84%E0%B8%84%E0%B8%A5%E0%B8%9C%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%AA%E0%B8%A2%E0%B8%87%E0%B8%AA%E0%B8%87-hr03?authuser=0"
          >
            อ่านเพิ่มเติม
          </MuiLink>
          )
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Hr03AccountSearchForm
            onSubmit={onSearchFormSubmit}
            loading={loading}
            onReset={() => dispatch(setFilter({}))}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Button
              component={Link}
              to="/hr03/add"
              variant="contained"
              color="success"
              startIcon={<Add />}
            >
              เพิ่มบัญชี
            </Button>
          </Stack>
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
          />
        </Paper>
      </Grid>
    </>
  );
}
