import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import UploadForm, { validate } from "./UploadForm";
import * as XLSX from "xlsx";
import { FORM_ERROR } from "final-form";
import fileDownload from "js-file-download";
// import jschardet from "jschardet";

export default function UploadPage() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [barColor, setBarColor] = useState("primary");

  //   const handleClose = () => {
  //     setLoading(false);
  //   };

  const onSubmit = async (formData, form, callback) => {
    setProgress(0);
    setLoading(true);
    const fileTotal = formData.template_files.length;
    let fileCount = 0;
    const outputFile = { name: null, data: [] };
    try {
      for (const tf of formData.template_files) {
        await readAndMergeFiles(tf, outputFile);
        setProgress(Math.floor((++fileCount * 100) / fileTotal));
        //       console.log(tf.name);
      }
    } catch (e) {
      console.log(e);
      setBarColor("error");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
      setBarColor("primary");
      return {
        [FORM_ERROR]: "เกิดข้อผิดพลาด",
      };
    }
    await new Promise((resolve) => setTimeout(resolve, 600));
    const blob = new Blob([outputFile.data.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });

    // download file
    let downloadFilename = "download.csv";
    let extractMatch = null;
    if (outputFile.name) {
      downloadFilename = outputFile.name;
    } else if (
      (extractMatch = /^(.+)\./.exec(formData.template_files[0].name))
    ) {
      downloadFilename = extractMatch[1] + ".csv";
    }

    fileDownload(blob, downloadFilename);
    // reader.readAsText(file, "windows-874");
    // reader.readAsText(file, "windows-874");
    form.restart();
    setLoading(false);
    //     callback();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={loading}
        // onClose={handleClose}
      >
        <DialogTitle>Loading....</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", p: 2 }}>
            <LinearProgress
              variant="determinate"
              color={barColor}
              value={progress}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Typography variant="h5"> File Normalization</Typography>
        <Typography variant="subtitle1">
          แปลงไฟล์ จากแต่ละ email ให้สามารถใช้งานได้
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={UploadForm}
          />
        </Paper>
      </Grid>
    </>
  );
}

const readAndMergeFiles = async (file, outputFile) => {
  if (file.name.endsWith(".csv")) {
    await readCSVFile(file, outputFile);
  } else if (
    file.name.endsWith(".xlsx") ||
    file.name.endsWith(".xlsb") ||
    file.name.endsWith(".xls")
  ) {
    // Process Excel file
    await readExcelFile(file, outputFile);
  }
};

const readCSVFile = (file, outputFile) => {
  return new Promise((resolve, reject) => {
    let retryRead = false;
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      if (!retryRead && csvData.match(/[^\u0E00-\u0E7F\u0020-\u007E\n\r]/)) {
        // not match unicode , thai charactor and newline
        retryRead = true;
        console.log("try read as winsdows-874");
        reader.readAsText(file, "windows-874");
        return;
      }
      //       const encoding = detectEncoding(e.target.result);
      //       const utf8Text = convertToUTF8(e.target.result, encoding);
      const lines = csvData.split("\n");

      // determine which template and how to slice
      let slice = [null];
      if (lines.length) {
        const headers = lines[0].split("|");
        // find first start col
        const startIndex = headers.findIndex(
          (h) => h.toUpperCase() === "AUTHORITYID"
        );
        if (startIndex !== -1) {
          if (headers.some((h) => h.toUpperCase() === "SEARCHKEY")) {
            // tp02
            slice = [startIndex, startIndex + 40];
          } else {
            // tp04
            slice = [startIndex, startIndex + 33];
          }
        }
      }

      lines.forEach((l) => {
        const line = l.trim();
        if (line === "") return;
        outputFile.data.push(
          line
            .split("|")
            .slice(...slice)
            .join("|")
        );
      });
      resolve(outputFile);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
    //     reader.readAsArrayBuffer(file);
  });
};

const readExcelFile = (file, outputFile) => {
  const sheetToCSV = (sheet) => {
    const csv = [];
    let a1Range = sheet["!ref"];

    if (sheet["!ref"]?.match(/.*[A-Z]1$/)) {
      a1Range = rangDetection(sheet);
    }

    const range = XLSX.utils.decode_range(a1Range);

    console.log("range read: " + a1Range);

    let dateColumn = [];
    let stringColumn = [];

    const columnCount = range.e.c;
    const currentDate = new Date();
    const formattedCurrentDate = currentDate
      .toISOString()
      .replace(/[:.]/g, "-");
    if (columnCount < 39) {
      //TP04
      outputFile.name = "TP04_" + formattedCurrentDate + ".csv";
      dateColumn = [3, 9, 11];
      stringColumn = [5, 7, 12, 14, 16, 17, 19];
    } else {
      //TP02
      outputFile.name = "TP02_" + formattedCurrentDate + ".csv";
      dateColumn = [3, 8, 13, 34, 36];
      stringColumn = [6, 14, 17];
    }

    console.log("row count: " + range.e.r);
    console.log("colunm count: " + range.e.c);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const row = [];
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const cellAddress = { c: colNum, r: rowNum };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cell = sheet[cellRef];

        // const dateValue = new Date(Math.round((cell.v - 25569) * 86400 * 1000));
        // const formattedDate = `${dateValue.getUTCDate().toString().padStart(2, '0')}/${(dateValue.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateValue.getUTCFullYear()}`;

        if (cell && cell.t === "n" && dateColumn.includes(colNum)) {
          const dateValue = new Date(
            Math.round((cell.v - 25569) * 86400 * 1000)
          );
          // Format as dd/mm/yyyy
          const formattedDate = `${dateValue
            .getUTCDate()
            .toString()
            .padStart(2, "0")}/${(dateValue.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}/${dateValue.getUTCFullYear()}`;
          //console.log(colNum,cell.t,cell.v,cell.w,formattedDate);
          row.push(formattedDate);
        } else if (cell && cell.t === "n" && stringColumn.includes(colNum)) {
          //console.log(colNum,cell.t,cell.v,cell.w);
          row.push(cell ? cell.w : "");
        } else {
          //   console.log(colNum, cell.t, cell.v, cell.w);
          row.push(cell ? cell.v : "");
        }

        //row.push(cell ? cell.w : '');
      }
      // Only add rows that contain data
      if (row.some((cell) => cell !== "")) {
        csv.push(row.join("|"));
      }
    }

    return csv.join("\n");
  };
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = sheetToCSV(sheet);
      outputFile.data.push(
        ...sheetData.split("\n").filter((line) => line.trim() !== "")
      );
      resolve(outputFile);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    // reader.readAsBinaryString(file);
    reader.readAsArrayBuffer(file);
  });
};

const rangDetection = (sheet) => {
  // Variables to track the range
  var minRow = Infinity,
    maxRow = -Infinity;
  var minCol = Infinity,
    maxCol = -Infinity;

  // Iterate over all cells in the sheet
  for (var cell in sheet) {
    if (cell[0] === "!") continue; // Skip special properties

    // Parse the cell address
    var address = XLSX.utils.decode_cell(cell);

    // Update the range
    if (address.r < minRow) minRow = address.r;
    if (address.r > maxRow) maxRow = address.r;
    if (address.c < minCol) minCol = address.c;
    if (address.c > maxCol) maxCol = address.c;
  }

  // Encode the calculated range
  var range = XLSX.utils.encode_range({
    s: { r: minRow, c: minCol },
    e: { r: maxRow, c: maxCol },
  });

  return range;
};

// Helper function to detect encoding
// const detectEncoding = (text) => {
//   const result = jschardet.detect(text);
//   return result.encoding;
// };

// Helper function to convert to UTF-8
// const convertToUTF8 = (text, fromEncoding) => {
//   console.log(text);
//   if (fromEncoding.toUpperCase() === "UTF-8") {
//     return text; // Already in UTF-8
//   }

//   const encoder = new TextEncoder(fromEncoding);
//   const utf8Bytes = encoder.encode(text);
//   const utf8Text = new TextDecoder("utf-8").decode(utf8Bytes);

//   return utf8Text;
// };
