import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import bankAccountTypes from "../../../../data/selects/bankAccountTypes";
import FieldDataGridRadio from "../../../form-fields/FieldDataGridRadio";
import FieldDataGridCheckBox from "../../../form-fields/FieldDataGridCheckbox";
import LetterForm from "./sub-forms/LetterForm";
import { useSelector } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  gridBooleanOperators,
  gridStringOperators,
} from "../../../../data/dataGrid";
import { useFormState } from "react-final-form";
import { thousands_separators } from "../../../../helpers/stringFormater";

const commonFilterOperator = gridStringOperators();
const isRequestedFilterOperator = gridBooleanOperators();
const KBNK_MAX_SELECTION = 1000;
const KBNK_ID = 2;

export default function AccountRequest({ readOnly, bankAccounts = [] }) {
  const [pageSize, setPageSize] = useState(5);
  const authRoles = useSelector((state) => state.auth.user.roles);
  const { values } = useFormState();

  const isAdmin = useMemo(
    () => [2].some((role) => authRoles[role]),
    [authRoles]
  );

  const AccountPicker = isAdmin ? FieldDataGridCheckBox : FieldDataGridRadio;

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "bank.name",
        headerName: "ธนาคาร",
        sortable: false,
        width: 100,
        valueGetter: (params) => {
          return params.row.bank?.short_name;
        },
        filterable: false,
        // filterOperators: commonFilterOperator,
      },
      {
        field: "number",
        headerName: "เลขบัญชี",
        sortable: false,
        minWidth: 200,
        maxWidth: 400,
        filterOperators: commonFilterOperator,
      },
      {
        field: "name",
        headerName: "ชื่อบัญชี",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        valueGetter: (params) => {
          return (
            params.row.name + (params.row.note ? `(${params.row.note})` : "")
          );
        },
        filterOperators: commonFilterOperator,
      },
      {
        field: "type",
        headerName: "ประเภท",
        sortable: false,
        minWidth: 80,
        maxWidth: 100,
        valueGetter: ({ row }) => {
          const accType = bankAccountTypes.find((e) => e.value === row.type_id);
          return accType?.label;
        },
        filterOperators: commonFilterOperator,
      },
      {
        field: "is_requested",
        headerName: "ส่งคำขอ",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        renderCell: (params) => {
          return params.row.is_requested ? <CheckCircle color="success" /> : "";
        },
        type: "boolean",
        filterOperators: isRequestedFilterOperator,
      },
    ];
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {readOnly ? (
            "บัญชี"
          ) : (
            <>
              {"เลือกบัญชี"}
              {!isAdmin ? "(1 บัญชี)" : " "}
              <Tooltip title="เพื่อให้การตอบกลับของข้อมูลมีประสิทธิภาพและมีความครบถ้วนจึงเปลี่ยนแปลงการรับส่งข้อมูลเป็น 1 คำขอต่อ 1 บัญชี">
                <InfoOutlined color="primary" fontSize="small" />
              </Tooltip>
            </>
          )}
        </Typography>
        {values.bank_id === KBNK_ID && isAdmin && (
          <Typography variant="subtitle2" color="warning.main">
            กรณีธนาคารกสิกรไทย เลือกได้ไม่เกิน{" "}
            {thousands_separators(KBNK_MAX_SELECTION)} บัญชีต่อ 1 คำขอ(หมาย)
          </Typography>
        )}
        <AccountPicker
          name="bank_accounts"
          disableColumnMenu
          columns={columns}
          rows={bankAccounts}
          // loading={loading}
          rowsPerPageOptions={[5, 10]}
          pageSize={pageSize}
          // pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowHeight={() => "auto"}
          autoHeight
          parse={(value) => {
            return value
              ? value.map((item) => {
                  return { bank_account_id: item };
                })
              : [];
          }}
          format={(value) => {
            return value ? value.map((item) => item.bank_account_id) : [];
          }}
          readOnly={readOnly}
          initialState={{
            columns: {
              columnVisibilityModel: {
                is_requested: !readOnly,
              },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "is_requested",
                    operatorValue: "is",
                  },
                ],
              },
            },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          // set maxinum number for KBNK case
          maxSelection={
            values.bank_id === KBNK_ID && isAdmin
              ? KBNK_MAX_SELECTION
              : undefined
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center"></Divider>
      </Grid>
      <LetterForm readOnly={readOnly} />
    </>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}
