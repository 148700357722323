import api, { setAuthorizationToken } from "../apis";
import {
  authApi,
  setAuthorizationToken as setAuthorizationToken2,
} from "../apis/apigw";
import _ from "lodash";
import LocalStorageService from "../helpers/LocalStorageService";

export const AUTH_FETCH = "auth/FETCH_AUTH";
export const AUTH_MARK_FETCHING = "auth/MARK_FETCHING_AUTH";
export const AUTH_LOGOUT = "auth/LOGOUT";

const defaultState = { isFetching: false, isAuthenticated: false, user: {} };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_FETCH:
      // const perms = _.mapKeys(action.payload.permissions, "name");
      return {
        ...state,
        isAuthenticated: true,
        user: { ...action.payload },
        // user: { ...action.payload, permissions: perms },
      };
    case AUTH_MARK_FETCHING:
      return { ...state, isFetching: action.payload };
    case AUTH_LOGOUT:
      return { ...state, isAuthenticated: false, user: {} };
    default:
      return state;
  }
}

// auth actions
export const authFetch = () => async (dispatch) => {
  const response = await api.get("/api/me");
  const payload = _.pick(response.data, [
    "id",
    "email",
    "fname",
    "lname",
    "job_title",
    "rank",
    // "permissions",
    // "training",
    "line_notify_link",
    "notify_token",
  ]);
  const roles = response.data.roles;
  if (Array.isArray(response.data.roles)) {
    payload.roles = _.keyBy(
      roles.map((r) => _.pick(r, ["id", "name"])),
      "id"
    );
  }

  if (response.data.policestation_org_code) {
    //load org
    const orgResp = await api.get(
      `/api/policestations/${response.data.policestation_org_code}`
    );
    payload.org = orgResp.data;
  }

  if (response.data.hr03_org_code) {
    // load hr03_org
    const hr03OrgResp = await api.get(
      `/api/policestations/${response.data.hr03_org_code}`
    );
    payload.hr03Org = hr03OrgResp.data;
  }

  if (response.data.viewer_org_code) {
    // load hr03_org
    const viewerOrgResp = await api.get(
      `/api/policestations/${response.data.viewer_org_code}`
    );
    payload.viewerOrg = viewerOrgResp.data;
  }
  // const { ORG_CODE, BK_CODE, BH_CODE } = payload.org;

  // // resolve BH
  // if (ORG_CODE === BH_CODE) {
  //   payload.org.BH_ORG = {
  //     CODE: ORG_CODE,
  //     ORG: payload.org.ORG,
  //     ORG_ABBR: payload.org.ORG_ABBR,
  //   };
  // } else if (BH_CODE) {
  //   const bhOrgData = (await api.get(`/api/policestations/${BH_CODE}`)).data;
  //   payload.org.BH_ORG = {
  //     CODE: BH_CODE,
  //     ORG: bhOrgData.ORG,
  //     ORG_ABBR: bhOrgData.ORG_ABBR,
  //   };
  // }

  // // resovle BK
  // if (ORG_CODE === BK_CODE) {
  //   payload.org.BK_ORG = {
  //     CODE: ORG_CODE,
  //     ORG: payload.org.ORG,
  //     ORG_ABBR: payload.org.ORG_ABBR,
  //   };
  // } else if (BK_CODE) {
  //   const bkOrgData = (await api.get(`/api/policestations/${BK_CODE}`)).data;
  //   payload.org.BK_ORG = {
  //     CODE: BK_CODE,
  //     ORG: bkOrgData.ORG,
  //     ORG_ABBR: bkOrgData.ORG_ABBR,
  //   };
  // }

  dispatch({
    type: AUTH_FETCH,
    payload,
  });
};

export const authMarkFetching = (isFetching) => {
  return { type: AUTH_MARK_FETCHING, payload: isFetching };
};

export const authLogin = (formValues) => async (dispatch) => {
  // await api.get("/sanctum/csrf-cookie");
  const response = await api.post("/api/login", {
    //     grant_type: "password",
    //     client_id: process.env.REACT_APP_CLIENT_ID,
    //     client_secret: process.env.REACT_APP_CLIENT_SECRET,
    email: formValues.email,
    password: formValues.password,
    //     scope: "",
  });

  const iresponse = await authApi.post("/api/ilogin", {
    username: formValues.email,
    password: formValues.password,
  });

  LocalStorageService.setToken(response.data);
  LocalStorageService.setItoken(iresponse.data);
  LocalStorageService.setUsername(formValues.email);
  setAuthorizationToken(LocalStorageService.getAccessToken());
  setAuthorizationToken2(LocalStorageService.getIaccessToken());

  // // more user info
  // await dispatch(authFetch());
  // dispatch({
  //   type: AUTH_FETCH,
  //   payload: {},
  // });

  try {
    await dispatch(authFetch());
  } catch (e) {
    setAuthorizationToken();
    setAuthorizationToken2();
    LocalStorageService.clearToken();
    throw e;
  }
};

export const authLogout = () => async (dispatch) => {
  const refreshToken = LocalStorageService.getIrefreshToken();
  const username = LocalStorageService.getUsername();
  try {
    await authApi.post("/api/ilogout", {
      refresh_token: refreshToken,
      username,
    });
  } catch (e) {
    console.log(e);
  } finally {
    setAuthorizationToken();
    setAuthorizationToken2();
    LocalStorageService.clearToken();
    dispatch({ type: AUTH_LOGOUT });
  }
};
