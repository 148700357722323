import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

export default function FieldSelect({
  name,
  label,
  options = [],
  required = false,
  readOnly = false,
  controlProp = {},
  selectProp = {},
}) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        return (
          <FormControl
            variant="outlined"
            error={(meta.error || meta.submitError) && meta.touched}
            {...controlProp}
          >
            <InputLabel
              id={`${name}-select-label`}
              required={required}
              error={(meta.error || meta.submitError) && meta.touched}
            >
              {label}
            </InputLabel>
            <Select
              {...selectProp}
              labelId={`${name}-select-label`}
              id={`${name}-field-select`}
              value={input.value}
              label={`${label}${required ? "*" : ""}`}
              onChange={(e) => input.onChange(e.target.value)}
              onClose={(e) => input.onBlur(e.target.value)}
              inputProps={{ readOnly }}
            >
              {options.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {(meta.error ||
              (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
              meta.touched && (
                <FormHelperText id={`${name}-error`} variant="outlined">
                  {meta.error || meta.submitError}
                </FormHelperText>
              )}
          </FormControl>
        );
      }}
    </Field>
  );
}
