import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import Joi from "joi";
import { FORM_ERROR } from "final-form";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";

export default function ProfileForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FieldInput
            name="rank"
            label="ยศ"
            controlProps={{ fullWidth: true }}
            inputProps={{ placeholder: "คำเต็ม เช่น พันตำรวจโท" }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="สกุล"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="job_title"
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            required
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/policestations"
            itemFormat={(row) => {
              return { value: row.ORG_CODE, label: row.ORG_ABBR };
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs md>
          <FieldInput
            name="pol_tel"
            label="โทรศัพท์"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validateRules = {
  rank: Joi.string().max(255).required(),
  fname: Joi.string().max(255).required(),
  lname: Joi.string().max(255).required(),
  job_title: Joi.string().max(255).required(),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required(),
  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .required(),
};

const schema = Joi.object(validateRules).error(validationMsg());

export const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};
