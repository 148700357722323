import { LockOpen, Save, Login } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Container,
  CssBaseline,
  Typography,
  Grid,
  Link,
  Stack,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useState } from "react";
import { Form } from "react-final-form";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import api from "../../../apis";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import { Link as RouterLink, useParams } from "react-router-dom";

const schema = Joi.object({
  password: Joi.string().min(8).required(),
  confirm_password: Joi.string().min(8).required(),
}).error(validationMsg());

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (values.password !== values.confirm_password) {
    errors.confirm_password = "ยืนยันรหัสผ่านไม่ถูกต้อง";
  }

  return errors;
};

export default function ForgotSetPasswordPage() {
  const [success, setSuccess] = useState(false);

  const params = useParams();
  const token = params.token;

  const onSubmit = (formValues) => {
    const submitValues = { ...formValues, forgot_token: token };
    return api
      .post(`api/forgot_password/set_password`, submitValues)
      .then(() => {
        setSuccess(true);
      })
      .catch(({ response }) => {
        if (response.status === 422 || response.status === 403) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOpen />
        </Avatar>
        <Typography component="h1" variant="h5">
          กำหนดรหัสผ่านใหม่
        </Typography>
        {/* <Typography component="h1" variant="subtitle1">
          โปรดระบุ email (username) ของท่านเพื่อส่งคำขอลืมรหัส
        </Typography> */}
        {success ? (
          <Box sx={{ mt: 1, pt: 2 }}>
            <Stack direction="column" spacing={2} alignItems="center">
              <Alert severity="success" sx={{ width: "100%" }}>
                <AlertTitle>กำหนดรหัสผ่านสำเร็จ</AlertTitle>
              </Alert>
              <Button
                component={RouterLink}
                to="/login"
                variant="outlined"
                startIcon={<Login />}
              >
                เข้าสู่ระบบ
              </Button>
            </Stack>
          </Box>
        ) : (
          <Form validate={validate} onSubmit={onSubmit}>
            {({
              handleSubmit,
              errors,
              error,
              submitError,
              submitting,
              pristine,
              initialValues,
              submitFailed,
              form,
              values,
            }) => {
              return (
                <Box
                  component="form"
                  noValidate
                  sx={{ mt: 1 }}
                  onSubmit={handleSubmit}
                >
                  {submitFailed && (error || submitError) && (
                    <Alert severity="warning" sx={{ width: "100%" }}>
                      {error || submitError}
                    </Alert>
                  )}
                  <FieldInput
                    name="password"
                    label="รหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                  />
                  <FieldInput
                    name="confirm_password"
                    label="ยืนยันรหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                  />
                  <SubmitBtn
                    variant="contained"
                    submitting={submitting}
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<Save />}
                    size="large"
                  >
                    บันทึก
                  </SubmitBtn>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      <Link component={RouterLink} to="/login" variant="body2">
                        กลับไปหน้าเข้าสู่ระบบ
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          </Form>
        )}
      </Box>
    </Container>
  );
}
