import api from "../apis";
import _ from "lodash";
// Actions
const FETCH = "hr03List/FETCH";
const CLEAR_RESULT = "hr03List/CLEAR_RESULT";
const MARK_LOADING = "hr03List/MARK_LOADING";
const SET_FILTER = "hr03List/SET_FILTER";
const FORCE_RELOAD = "hr03List/FORCE_RELOAD";
const CHANGE_PAGE = "hr03List/CHANGE_PAGE";
const CHANGE_PAGE_SIZE = "hr03List/CHANGE_PAGE_SIZE";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 20,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0, page: 0 },
      };
    case MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
          page: 0,
        },
      };
    case FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        meta: {
          ...state.meta,
          page: 0,
          pageSize: action.payload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const fetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    let response = null;

    dispatch(markLoading(true));

    try {
      response = await api.get(`/api/hr_reports`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      console.log("action error");
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.log(errors);
      }
      dispatch(clearResult());
      throw e;
    }
  };

export const markLoading = (isLoading = true) => {
  return { type: MARK_LOADING, payload: isLoading };
};

export const clearResult = () => {
  return { type: CLEAR_RESULT };
};

export const setFilter = (filterValue) => {
  return { type: SET_FILTER, payload: filterValue };
};

export const forceReload = () => {
  return { type: FORCE_RELOAD };
};

export const changePage = (page) => {
  return { type: CHANGE_PAGE, payload: page };
};

export const changePageSize = (pageSize) => {
  return { type: CHANGE_PAGE_SIZE, payload: pageSize };
};
