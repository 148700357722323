import api from "../apis";
// Actions
const ACL_FETCH = "accountList/FETCH";
const ACL_CLEAR_RESULT = "accountList/CLEAR_RESULT";
const ACL_MARK_LOADING = "accountList/MARK_LOADING";
const ACL_SET_FILTER = "accountList/SET_FILTER";
const ACL_FORCE_RELOAD = "accountList/FORCE_RELOAD";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case ACL_FETCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
        },
      };
    case ACL_CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0 },
      };
    case ACL_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case ACL_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
        },
      };
    case ACL_FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const fetch =
  (pageSize, pageIndex, filter, fetchId, fetchIdRef, caseId) =>
  async (dispatch) => {
    const response = await api.get(`/api/cases/${caseId}/bank_accounts`, {
      params: {
        page_size: pageSize,
        page: pageIndex,
        ...filter,
      },
    });
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      dispatch({
        type: ACL_FETCH,
        payload: { ...response.data, pageSize, pageIndex },
      });
    }
  };

export const markLoading = (isLoading) => {
  return { type: ACL_MARK_LOADING, payload: isLoading };
};

export const clearResult = () => {
  return { type: ACL_CLEAR_RESULT };
};

export const setFilter = (filterValue) => {
  return { type: ACL_SET_FILTER, payload: filterValue };
};

export const forceReload = () => {
  return { type: ACL_FORCE_RELOAD };
};
