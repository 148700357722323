import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function RequireRoles({ children, roleIds, navigate = false }) {
  const authRoles = useSelector((state) => state.auth.user.roles);

  if (roleIds.some((role) => authRoles[role])) {
    return children;
  }

  return navigate ? <Navigate to="/" /> : null;
}
