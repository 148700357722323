import React, { useMemo } from "react";
import { Paper, Typography } from "@mui/material";
import { Form } from "react-final-form";
import { Alert, Button, Grid, Stack } from "@mui/material";
import FieldInput from "../../../form-fields/FieldInput";

export default function AccountAddCsvResultView({ handleCancel, response }) {
  const initData = useMemo(
    () => response.data?.unsave_bank_accounts?.join("\n"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderHeader = () => {
    if (response.status >= 200 && response.status < 300) {
      return (
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Csv Import Success
        </Typography>
      );
    }

    return (
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
        color="error"
      >
        Csv Import Errors
      </Typography>
    );
  };

  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {renderHeader()}
        <Form
          initialValues={{ unsave_bank_accounts: initData }}
          onSubmit={() => undefined}
        >
          {() => {
            return (
              <Grid container spacing={2}>
                {response.data?.message && (
                  <Grid item xs={12}>
                    <Alert severity="warning" sx={{ width: "auto" }}>
                      {response.data.message}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FieldInput
                    name="unsave_bank_accounts"
                    label="Errors"
                    controlProps={{ fullWidth: true }}
                    inputProps={{
                      multiline: true,
                      maxRows: 6,
                      minRows: 3,
                    }}
                    readOnly
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined" onClick={handleCancel}>
                      ปิด
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        </Form>
      </Paper>
    </>
  );
}
