import api from "../apis";
// Actions
const CS_SEARCH = "caseSearch/SEARCH";
const CS_CLEAR_RESULT = "caseSearch/CLEAR_RESULT";
const CS_MARK_LOADING = "caseSearch/MARK_LOADING";
const CS_SET_FILTER = "caseSearch/SET_FILTER";
const FORCE_RELOAD = "caseSearch/FORCE_RELOAD";

const defaultState = {
  data: [],
  meta: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CS_SEARCH:
      return {
        ...state,
        data: action.payload.rows,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
        },
      };
    case CS_CLEAR_RESULT:
      return {
        ...state,
        data: [],
        meta: { ...state.meta, loading: false, total: 0 },
      };
    case CS_MARK_LOADING:
      return {
        ...state,
        meta: { ...state.meta, loading: action.payload },
      };
    case CS_SET_FILTER:
      return {
        ...state,
        meta: {
          ...state.meta,
          filter: action.payload,
        },
      };
    case FORCE_RELOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          forceReload: ++state.meta.forceReload,
        },
      };
    default:
      return state;
  }
}

//action creator
export const search =
  (pageSize, pageIndex, filter, fetchId, fetchIdRef) => async (dispatch) => {
    const response = await api.get("api/cases", {
      params: {
        page_size: pageSize,
        page: pageIndex,
        ...filter,
      },
    });
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      dispatch({
        type: CS_SEARCH,
        payload: { ...response.data, pageSize, pageIndex },
      });
    }
  };

export const markLoading = (isLoading) => {
  return { type: CS_MARK_LOADING, payload: isLoading };
};

export const clearResult = () => {
  return { type: CS_CLEAR_RESULT };
};

export const setFilter = (filterValue) => {
  return { type: CS_SET_FILTER, payload: filterValue };
};

export const forceReload = () => {
  return { type: FORCE_RELOAD };
};
