import { DateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
export default function FieldComponent({
  name,
  label,
  required = false,
  readOnly = false,
  pickerProps = {},
  input,
  meta,
}) {
  const [emptyValue, setEmptyValue] = useState(false);

  // reset textfield value when field is focused (such as using enter to submit form and then have to clear this field)
  useEffect(() => {
    if (!meta.pristine || meta.initial) {
      return undefined;
    }
    setEmptyValue(true);
  }, [meta.pristine, meta.initial]);

  return (
    <DateTimePicker
      {...pickerProps}
      label={`${label}${required ? " *" : ""}`}
      value={input.value}
      readOnly={readOnly}
      onChange={(newValue) => {
        //       console.log(newValue);
        input.onChange(newValue);
      }}
      renderInput={(params) => {
        //       console.log(params);
        const originalOnBlur = params.inputProps.onBlur;
        params.inputProps.onBlur = (e) => {
          input.onBlur();
          originalOnBlur(e);
        };

        params.error = (meta.error || meta.submitError) && meta.touched;
        if (
          (meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
          meta.touched
        ) {
          params.helperText = meta.error || meta.submitError;
        }

        const originalOnChange = params.inputProps.onChange;
        params.inputProps.onChange = (e) => {
          if (emptyValue) {
            setEmptyValue(false);
          }
          originalOnChange(e);
        };

        if (!input.value && emptyValue) {
          params.inputProps.value = "";
        }

        return <TextField {...params} />;
      }}
      onClose={() => {
        input.onBlur();
      }}
      onOpen={() => {
        if (emptyValue) {
          setEmptyValue(false);
        }
      }}
    />
  );
}
