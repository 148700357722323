import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Field } from "react-final-form";

export default function FieldDataGridCheckbox({
  name,
  parse,
  format,
  readOnly = false,
  maxSelection,
  ...rest
}) {
  return (
    <Field name={name} parse={parse || undefined} format={format || undefined}>
      {({ input, meta }) => (
        <>
          {(meta.error ||
            (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
            meta.touched && (
              <Typography variant="caption" color="error.main">
                {meta.error || meta.submitError}
              </Typography>
            )}
          <DataGrid
            {...rest}
            checkboxSelection={!readOnly}
            onSelectionModelChange={(newSelectionModel) => {
              if (maxSelection && newSelectionModel.length > maxSelection) {
                input.onChange(newSelectionModel.slice(0, maxSelection));
              } else {
                input.onChange(newSelectionModel);
              }
              input.onBlur();
            }}
            selectionModel={input.value || []}
            disableSelectionOnClick={readOnly}
          />
        </>
      )}
    </Field>
  );
}
