import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import FieldAccountStatements from "../../../form-fields/customs/FieldAccountStatements";
import { format, isValid, parseISO } from "date-fns";
import LetterForm from "./sub-forms/LetterForm";
import bankAccountTypes from "../../../../data/selects/bankAccountTypes";

export default function StatementRequest({ readOnly, bankAccounts = [] }) {
  const [pageSize, setPageSize] = useState(10);

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "bank.name",
        headerName: "ธนาคาร",
        sortable: false,
        width: 100,
        valueGetter: (params) => {
          return params.row.bank?.short_name;
        },
      },
      {
        field: "number",
        headerName: "เลขบัญชี",
        sortable: false,
        minWidth: 200,
        maxWidth: 400,
      },
      {
        field: "name",
        headerName: "ชื่อบัญชี",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        valueGetter: (params) => {
          return (
            params.row.name + (params.row.note ? `(${params.row.note})` : "")
          );
        },
      },
      {
        field: "type",
        headerName: "ประเภท",
        sortable: false,
        minWidth: 80,
        maxWidth: 100,
        valueGetter: ({ row }) => {
          const accType = bankAccountTypes.find((e) => e.value === row.type_id);
          return accType?.label;
        },
      },
      {
        field: "from_date",
        headerName: "ข้อมูลวันที่",
        sortable: false,
        minWidth: 120,
        maxWidth: 160,
        valueFormatter: ({ value }) => {
          let date = null;
          if (!value || !isValid((date = parseISO(value)))) {
            return "";
          }
          return format(date, "dd/MM/yyyy");
        },
      },
      {
        field: "to_date",
        headerName: "ถึงวันที่",
        sortable: false,
        minWidth: 120,
        maxWidth: 160,
        valueFormatter: ({ value }) => {
          let date = null;
          if (!value || !isValid((date = parseISO(value)))) {
            return "";
          }
          return format(date, "dd/MM/yyyy");
        },
      },
      {
        field: "is_requested",
        headerName: "ส่งคำขอ",
        sortable: false,
        minWidth: 60,
        maxWidth: 100,
        renderCell: (params) => {
          return params.row.is_requested ? <CheckCircle color="success" /> : "";
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          {readOnly ? (
            "บัญชี"
          ) : (
            <>
              {"เลือกบัญชี (1 บัญชี) "}
              <Tooltip title="เพื่อให้การตอบกลับของข้อมูลมีประสิทธิภาพและมีความครบถ้วนจึงเปลี่ยนแปลงการรับส่งข้อมูลเป็น 1 คำขอต่อ 1 บัญชี">
                <InfoOutlined color="primary" fontSize="small" />
              </Tooltip>
            </>
          )}
        </Typography>
        <FieldAccountStatements
          name="bank_accounts"
          disableColumnMenu
          columns={columns}
          rows={bankAccounts}
          // loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          // pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          getRowHeight={() => "auto"}
          readOnly={readOnly}
          initialState={{
            columns: {
              columnVisibilityModel: {
                is_requested: !readOnly,
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center"></Divider>
      </Grid>
      <LetterForm readOnly={readOnly} />
    </>
  );
}
