import { CheckCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/system";
import React from "react";
import AlertDialog from "./AlertDialog";

export default function AlertIconDialog({
  children,
  icon,
  iconColor,
  ...props
}) {
  const IconComponent = icon || CheckCircleOutline;
  return (
    <AlertDialog {...props}>
      <Box sx={{ textAlign: "center" }}>
        <IconComponent
          sx={{ fontSize: 100, color: iconColor || "success.main" }}
        />
        {children}
      </Box>
    </AlertDialog>
  );
}
