import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import CctvForm, { validateRules } from "./CctvForm";
import { Add, Delete, Edit } from "@mui/icons-material";
import { format, isValid, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useField } from "react-final-form";
import {
  addRow,
  changePageSize,
  cleanup,
  initAccounts,
  initOnlyRows,
  removeRow,
  setRow,
} from "../../../../ducks/requestAccountsPickerField";
import { useParams } from "react-router-dom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function FieldComponent({
  name,
  readOnly = false,
  input,
  meta,
  rows,
  ...rest
}) {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.req18f.loading);
  const loadError = useSelector((state) => state.req18f.error);
  const pageSize = useSelector((state) => state.req18f.grid.pageSize);
  const gridRows = useSelector((state) => state.req18f.grid.rows);
  const reqBank = useSelector((state) => state.req18f.reqBank);
  const preAccounts = useSelector((state) => state.req18f.preAccounts);

  const bankIdField = useField("bank_id");

  const columns = [
    {
      field: "action",
      headerName: "จัดการ",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack spacing={1} direction="row">
            <IconButton
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={() =>
                handleOpenModal(
                  <CctvForm
                    header="แก้ไขข้อมูลบัญชี"
                    onSubmit={(formValues) => {
                      const account = preAccounts.find(
                        (e) => e.id === formValues.bank_account_id
                      );

                      const editIndex = gridRows.findIndex(
                        (e) => e.id === params.id
                      );
                      const formData = [...input.value];
                      formData[editIndex] = formValues;

                      dispatch(
                        setRow(
                          {
                            ...formValues,
                            bank: reqBank,
                            account,
                          },
                          params.id
                        )
                      );
                      input.onChange(formData);
                      handleCloseModal();
                    }}
                    handleCloseModal={handleCloseModal}
                    initialValues={_.pick(
                      params.row,
                      Object.keys(validateRules())
                    )}
                  />
                )
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              type="button"
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                const removeIndex = gridRows.findIndex(
                  (e) => e.id === params.id
                );
                input.onChange(
                  input.value?.filter((e, idx) => idx !== removeIndex)
                );

                dispatch(removeRow(params.id));
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "short_name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "number",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 400,
      valueGetter: (params) => {
        return params.row.account.number;
      },
    },
    {
      field: "name",
      headerName: "ชื่อบัญชี",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
      valueGetter: (params) => {
        return (
          params.row.account.name +
          (params.row.account.note ? `(${params.row.account.note})` : "")
        );
      },
    },
    {
      field: "branch_name",
      headerName: "สาขา",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: "from_date",
      headerName: "ข้อมูลวันที่",
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
      valueFormatter: ({ value }) => {
        let date = null;
        if (!value || !isValid((date = parseISO(value)))) {
          return "";
        }
        return format(date, "dd/MM/yyyy HH:mm");
      },
    },
    {
      field: "to_date",
      headerName: "ถึงวันที่",
      sortable: false,
      minWidth: 160,
      maxWidth: 160,
      valueFormatter: ({ value }) => {
        let date = null;
        if (!value || !isValid((date = parseISO(value)))) {
          return "";
        }
        return format(date, "dd/MM/yyyy HH:mm");
      },
    },
  ];

  // first initial
  useEffect(() => {
    if (rows) {
      dispatch(initOnlyRows(rows));
      return () => {
        input.onChange(undefined);
        dispatch(cleanup());
      };
    }

    dispatch(initAccounts(bankIdField.input.value, caseId));

    return () => {
      input.onChange(undefined);
      dispatch(cleanup());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, bankIdField.input.value, caseId, dispatch]);

  // add account modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = () => {
    setOpenModal(false);
    input.onBlur();
  };

  const errorShow = meta.error || meta.submitError;

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (loadError) {
    return (
      <Typography variant="subtitle1" color="error">
        Error, Please try again.
      </Typography>
    );
  }

  return (
    <Grid item container>
      {!readOnly && (
        <Grid item xs={12}>
          <Button
            type="button"
            size="small"
            variant="contained"
            color="success"
            startIcon={<Add />}
            sx={{ mb: 1 }}
            onClick={() =>
              handleOpenModal(
                <CctvForm
                  header="เพิ่มข้อมูลบัญชี"
                  onSubmit={(formValues) => {
                    const account = preAccounts.find(
                      (e) => e.id === formValues.bank_account_id
                    );

                    dispatch(addRow({ ...formValues, bank: reqBank, account }));

                    input.onChange([...input.value, formValues]);
                    handleCloseModal();
                  }}
                  handleCloseModal={handleCloseModal}
                />
              )
            }
          >
            เพิ่ม
          </Button>
        </Grid>
      )}
      {(meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
        _.isString(errorShow) &&
        meta.touched && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error">
              {errorShow}
            </Typography>
          </Grid>
        )}
      <Grid item xs={12}>
        <Modal
          open={openModal ? true : false}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>{openModal}</Box>
        </Modal>
        <DataGrid
          {...rest}
          columns={columns}
          rows={gridRows}
          disableColumnMenu
          rowsPerPageOptions={[5, 10, 20]}
          pageSize={pageSize}
          // pagination
          onPageSizeChange={(newPageSize) =>
            dispatch(changePageSize(newPageSize))
          }
          autoHeight
          getRowHeight={() => "auto"}
          initialState={{
            columns: {
              columnVisibilityModel: {
                action: !readOnly,
              },
            },
          }}
          disableSelectionOnClick={readOnly}
        />
      </Grid>
    </Grid>
  );
}
