const letterTypes = [
  {
    value: 8,
    label: "หนังสือยืนยันการรับแจ้งตาม พรก.ป้องอาชญากรรมทางเทคโนโลยี (H)",
    desc: "หนังสือยืนยันการรับแจ้งตาม พรก.ป้องอาชญากรรมทางเทคโนโลย๊",
    type_name: "RequestConfirmCfrDetail",
    dest_type: "bank",
  },
  {
    value: 1,
    label: "ขอข้อมูลบัญชีด่วน/ขอไล่เส้นเงินด่วน",
    desc: "ขอข้อมูลบัญชีด่วน/ขอไล่เส้นเงินด่วน",
    type_name: "AppModelsRequestUrgentDetail",
    role: 2,
    dest_type: "bank",
  },
  {
    value: 2,
    label: "หมายขอข้อมูลที่ใช้เปิดบัญชี (A)",
    desc: "หมายสำหรับขอข้อมูลพื้นฐานของบัญชีธนาคาร เช่น ชื่อ,เลขบัตร,เบอร์โทร,ยิดเงินคงเหลือ",
    type_name: "AppModelsRequestAccountDetail",
    dest_type: "bank",
  },
  {
    value: 3,
    label: "หมายขอข้อมูลรายการเดินบัญชี (B)",
    desc: "หมายสำหรับขอข้อมูลรายการเดินบัญชี",
    type_name: "AppModelsRequestStatementDetail",
    dest_type: "bank",
  },
  {
    value: 5,
    label: "หมายอายัดเฉพาะยอดเงินที่ระบุ (Q)",
    desc: "หมายสำหรับขออายัดบัญชีธนาคารเฉพาะยอดเงิน",
    type_name: "AppModelsRequestFreezeAmountDetail",
    dest_type: "bank",
  },
  {
    value: 4,
    label: "หมายอายัดทั้งบัญชี (R)",
    desc: "หมายสำหรับขออายัดบัญชีธนาคาร",
    type_name: "AppModelsRequestFreezeDetail",
    dest_type: "bank",
  },
  {
    value: 6,
    label: "หมายขอข้อมูล ภาพจากตู้ ATM ADM (D)",
    desc: "หมายขอข้อมูล ภาพจากตู้ ATM ADM",
    type_name: "AppModelsRequestAtmDetail",
    dest_type: "bank",
  },
  {
    value: 7,
    label: "หมายขอข้อมูล ภาพจาก CCTV (E)",
    desc: "หมายขอข้อมูล ภาพจาก CCTV",
    type_name: "AppModelsRequestCctvDetail",
    dest_type: "bank",
  },

  {
    value: 9,
    label: "หมายขอข้อมูลจดทะเบียน (Telco A)",
    desc: "หมายขอข้อมูลจดทะเบียนเบอร์",
    type_name: "AppModelsRequestTelcoAccountDetail",
    dest_type: "telco",
  },
  {
    value: 10,
    label: "หมายขอข้อมูล CDR (Telco B)",
    desc: "หมายขอข้อมูล CDR",
    type_name: "AppModelsRequestTelcoCdrDetail",
    dest_type: "telco",
  },

  // {
  //   value: 9,
  //   label: "หมายขอข้อมูล ขอเลขบัญชีเต็ม (G)",
  //   desc: "หมายขอข้อมูล ขอเลขบัญชีเต็ม",
  //   type_name: "AppModelsRequestFreezeDetail",
  // },
  // {
  //   value: 10,
  //   label: "หมายไล่เส้นทางการเงิน, ขอข้อมูล (H)",
  //   desc: "หมายไล่เส้นทางการเงิน, ขอข้อมูล",
  //   type_name: "AppModelsRequestFreezeDetail",
  // },
];

export default letterTypes;
