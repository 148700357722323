import api from "../../apis";

export const banksOptionsQuery = () => ({
  queryKey: ["banks", "select"],
  queryFn: () =>
    api.get("/api/banks/list/bank").then((res) =>
      res.data.map((row) => ({
        value: row.id,
        label: `${row.name} (${row.short_name})`,
      }))
    ),
  staleTime: Infinity,
});
