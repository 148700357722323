import {
  Alert,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mrqChangePage,
  mrqChangePageSize,
  mrqClearResult,
  mrqFetch,
  mrqForceReload,
  mrqSetFilter,
} from "../../../ducks/mgtRequestList";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { getChip } from "../../../data/requestStatus";
import { ForwardToInbox, Refresh, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { format, isValid, parseISO } from "date-fns";
import NewRequestView from "./NewRequestView";
import OperatorSearchForm, { validate } from "./OperatorSearchForm";
import { Form } from "react-final-form";
import {
  Link,
  Navigate,
  useMatch,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import CheckedRequestView from "./CheckedRequestView";
import RequestView from "./RequestView";

export default function RequestListPage() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();

  let resolved = useResolvedPath("/admin/request-mgt");
  let isAdminPath = useMatch({ path: resolved.pathname, end: false });

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(mrqClearResult());
      dispatch(mrqSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.mgtRequestList.data);
  const total = useSelector((state) => state.mgtRequestList.meta.total);
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);
  const forceReload = useSelector(
    (state) => state.mgtRequestList.meta.forceReload
  );
  const page = useSelector((state) => state.mgtRequestList.meta.page);
  const pageSize = useSelector((state) => state.mgtRequestList.meta.pageSize);
  const filter = useSelector((state) => state.mgtRequestList.meta.filter);

  // const [rowsState, setRowsState] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(mrqFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)).catch(
      (e) => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(mrqForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const renderAction = ({ row }) => {
    if (!isAdminPath) {
      // same as RequestListView.js
      return (
        <IconButton
          component={Link}
          to={`/cases/${row.case_id}/requests/${row.id}`}
          type="button"
          variant="contained"
          size="small"
          // onClick={() => {
          //   handleOpenModal(
          //     <RequestView
          //       handleCancel={handleCloseModal}
          //       requestId={params.id}
          //     />
          //   );
          // }}
        >
          {row.status === "new" ? (
            <ForwardToInbox color="primary" />
          ) : (
            <Visibility />
          )}
        </IconButton>
      );
    }

    let modalView = null;

    switch (row.status) {
      case "sent_to_contact_person":
        modalView = (
          <NewRequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
          />
        );
        break;
      case "checked":
        modalView = (
          <CheckedRequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
          />
        );
        break;
      default:
        modalView = (
          <RequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
          />
        );
    }

    return (
      <IconButton
        type="button"
        variant="contained"
        size="small"
        onClick={() => {
          handleOpenModal(modalView);
        }}
      >
        <Visibility />
      </IconButton>
    );
  };

  const renderTitle = () => {
    switch (reqStatus) {
      case "sent_to_contact_person":
        return (
          <Stack direction="row" spacing={1}>
            <Typography variant="h5">คำขอเข้าใหม่</Typography>
            {getChip(reqStatus)}
          </Stack>
        );
      case "checked":
        return (
          <Stack direction="row" spacing={1}>
            <Typography variant="h5">คำขอตรวจแล้ว</Typography>
            {getChip(reqStatus)}
          </Stack>
        );
      default:
        return <Typography variant="h5">รายการคำขอทั้งหมด</Typography>;
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Stack
            spacing={1}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%" }}
          >
            {renderAction(params)}
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 280,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
    {
      field: "description",
      headerName: "บัญชี",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(mrqSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        {renderTitle()}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 900,
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSearchSubmit}
            component={OperatorSearchForm}
            loading={loading}
            onReset={() => dispatch(mrqSetFilter({}))}
            showStatusField={reqStatus ? false : true}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(mrqChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(mrqChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(mrqForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
