import { Divider, Grid, InputAdornment } from "@mui/material";
import React from "react";
import FieldInput from "../../../../form-fields/FieldInput";
import FieldDatePicker from "../../../../form-fields/FieldDatePicker";
import FieldAsyncAutoComplete from "../../../../form-fields/FieldAsyncAutoComplete";
import ReuseSignature from "./ReuseSignature";
import Joi from "joi";
import { defaultJoiDates } from "../../../../../helpers/dateHelper";
import { startOfToday } from "date-fns";

export default function LetterForm({ readOnly }) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <FieldInput
          name="no"
          label="เลขหนังสือ"
          required
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">ตช.</InputAdornment>
            ),
            placeholder: "ตัวอย่างเช่น 0039.12/123",
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldDatePicker
          name="date"
          label="ลงวันที่"
          required
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="center">พงส.</Divider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <FieldInput
          name="rank"
          label="ยศ"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          inputProps={{ placeholder: "คำเต็ม เช่น พันตำรวจโท" }}
          required
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <FieldInput
          name="fname"
          label="ชื่อ"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <FieldInput
          name="lname"
          label="สกุล"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldInput
          name="job_title"
          label="ตำแหน่ง"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldAsyncAutoComplete
          name="policestation_org_code"
          label="หน่วยงาน"
          required
          controlProp={{ fullWidth: true }}
          readOnly={readOnly}
          basePath="/api/policestations"
          itemFilter={(row) =>
            !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
          }
          itemFormat={(row) => {
            return { value: row.ORG_CODE, label: row.ORG_ABBR };
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldInput
          name="email"
          label="email"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FieldInput
          name="tel"
          label="โทรศัพท์"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldInput
          name="address"
          label="ที่อยู่"
          controlProps={{ fullWidth: true }}
          readOnly={readOnly}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ReuseSignature name="signature" readOnly={readOnly} />
      </Grid>
    </>
  );
}

export const validateLetterRules = () => ({
  no: Joi.string().pattern(/^\d.*/).max(80).required(),
  date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required(),
  fname: Joi.string().max(255).required(),
  lname: Joi.string().max(255).required(),
  rank: Joi.string().max(255).required(),
  job_title: Joi.string().max(255).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(null),
  tel: Joi.string()
    .pattern(/^\d{10}$/)
    .allow(null),
  address: Joi.string().max(255).allow(null),
  signature: Joi.any().required(),
  policestation_org_code: Joi.string()
    .pattern(/^[2-9]\d{4}$/)
    .required(),
});

export const letterValidationMsg = {
  no: { stringPattern: "ขึ้นต้นด้วยตัวเลขเท่านั้น" },
};
