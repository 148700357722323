import { Save } from "@mui/icons-material";
import { Alert, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";
import _ from "lodash";
import validationMsg from "../../helpers/validationMsg";
import api from "../../apis";
import { useDispatch } from "react-redux";
import { authLogout } from "../../ducks/auth";

export default function ChPassowrdPage() {
  const dispatch = useDispatch();
  const onSubmit = (formValues) => {
    return api
      .put(`api/change_password`, formValues)
      .then(() => {
        dispatch(authLogout());
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">เปลี่ยนรหัสผ่าน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form validate={validate} onSubmit={onSubmit}>
            {({
              handleSubmit,
              errors,
              error,
              submitError,
              submitting,
              pristine,
              initialValues,
              submitFailed,
              form,
              values,
            }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    {submitFailed && (error || submitError) && (
                      <Grid item md={12}>
                        <Alert severity="warning" sx={{ width: "100%" }}>
                          {error || submitError}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FieldInput
                        name="password"
                        label="รหัสผ่านปัจจุบัน"
                        required
                        controlProps={{
                          fullWidth: true,
                          type: "password",
                        }}
                        inputType="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldInput
                        name="new_password"
                        label="รหัสผ่านใหม่"
                        required
                        controlProps={{
                          fullWidth: true,
                          type: "password",
                        }}
                        inputType="password"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FieldInput
                        name="confirm_password"
                        label="ยืนยันรหัสผ่านใหม่"
                        required
                        controlProps={{
                          fullWidth: true,
                          type: "password",
                        }}
                        inputType="password"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Stack direction="row" spacing={2}>
                        <SubmitBtn
                          variant="contained"
                          startIcon={<Save />}
                          submitting={submitting}
                          pristine={pristine}
                        >
                          บันทึก
                        </SubmitBtn>
                        <Button
                          component={Link}
                          type="button"
                          variant="outlined"
                          disabled={submitting}
                          to="/"
                        >
                          ยกเลิก
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </Paper>
      </Grid>
    </>
  );
}

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (values.new_password !== values.confirm_password) {
    errors.confirm_password = "ยืนยันรหัสผ่านไม่ถูกต้อง";
  }

  return errors;
};

const schema = Joi.object({
  password: Joi.string().min(8).required(),
  new_password: Joi.string().min(8).required(),
  confirm_password: Joi.string().min(8).required(),
}).error(validationMsg());
