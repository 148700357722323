import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import Joi from "joi";
import _ from "lodash";

function validate(values, props) {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });
  // console.log(vResult);
  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors._error = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
}

const schema = Joi.object({
  search: Joi.string().min(3).max(50).allow(null),
});

export default function CaseSearchForm({ onSubmit, loading }) {
  return (
    <Form validate={validate} onSubmit={onSubmit}>
      {({
        handleSubmit,
        error,
        submitting,
        pristine,
        initialValues,
        submitFailed,
        form,
        values,
      }) => {
        //     console.log(values);
        //     console.log(submitting);
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <FieldInput
                    name="search"
                    label="คำค้นหา"
                    controlProps={{ fullWidth: true }}
                  />
                  <SubmitBtn
                    variant="contained"
                    submitting={loading}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </SubmitBtn>
                  {/* <Button variant="outlined"> 
		      ค้นหา
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}
