import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

export default function FieldCheckBox({
  name,
  label,
  required = false,
  readOnly = false,
  controlProps = {},
  inputProps = {},
  inputLabelProps = {},
}) {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => (
        <FormControlLabel
          disabled={readOnly}
          control={<Checkbox {...inputProps} {...input} />}
          label={label}
        />
      )}
    </Field>
  );
}
