import { AUTH_LOGOUT } from "./auth";

export const SET_PID = "tpoCase/SET_PID";

const defaultState = { pid: null };

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PID:
      return {
        ...state,
        pid: action.payload,
      };
    case AUTH_LOGOUT:
      return { ...defaultState };
    default:
      return state;
  }
}

export const setPid = (pid) => {
  return { type: SET_PID, payload: pid };
};
