const types = [
  { id: 1, name: "HR03-1" },
  { id: 2, name: "HR03-2" },
  { id: 3, name: "HR03-1(AOC)" },
];

export const hr03Select = types.map((item) => ({
  value: item.id,
  label: item.name,
}));

export const getHR03LabelById = (id) => {
  const label = types.find((item) => item.id === id);
  return label?.name || "unknown";
};
